import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    TimeInput,
    Input,
    DatePicker,
    Textarea,
    Select,
    SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";

const TripForm = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [tripType, setTripType] = useState("");

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate("/manager/trip");
    };

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <h1 className="report-heading">Add Trip Details</h1>
            <div className="px-4">
                <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div>
                            <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Vehicle Reg no"
                                        labelPlacement="outside"
                                        type="number"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Driver Name"
                                        labelPlacement="outside"
                                    />
                                    <DatePicker
                                        variant="bordered"
                                        label="Trip Date"
                                        labelPlacement="outside"
                                    />
                                </div>

                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Customer Name"
                                        labelPlacement="outside"
                                        type="number"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Material"
                                        labelPlacement="outside"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Waiting Time"
                                        labelPlacement="outside"
                                    />
                                </div>

                                <div className="form-row  form-row2">
                                    <Select
                                        label="Trip Type"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                        onChange={(e) => setTripType(e.target.value)}
                                    >
                                        <SelectItem key="Day Trip" value="Day Trip">
                                            Day Trip
                                        </SelectItem>
                                        <SelectItem key="Load Trip" value="Load Trip">
                                            Load Trip
                                        </SelectItem>
                                    </Select>
                                </div>

                                {tripType === "Day Trip" && (
                                    <>
                                        <div className="form-row">
                                            <Input
                                                variant="bordered"
                                                label="Starting Time"
                                                labelPlacement="outside"
                                            />
                                            <Input
                                                variant="bordered"
                                                label="Ending Time"
                                                labelPlacement="outside"
                                            />
                                            <Input
                                                variant="bordered"
                                                label="Number Of Hrs"
                                                labelPlacement="outside"
                                            />
                                        </div>
                                        <div className="form-row">
                                            <Input
                                                variant="bordered"
                                                label="Over Time"
                                                labelPlacement="outside"
                                            />
                                            <Input
                                                variant="bordered"
                                                label="From Location"
                                                labelPlacement="outside"
                                            />
                                            <Input
                                                variant="bordered"
                                                label="To Location"
                                                labelPlacement="outside"
                                            />
                                        </div>
                                    </>
                                )}

                                {tripType === "Load Trip" && (
                                    <div className="form-row">
                                        <Input
                                            variant="bordered"
                                            label="From Location"
                                            labelPlacement="outside"
                                        />
                                        <Input
                                            variant="bordered"
                                            label="To Location"
                                            labelPlacement="outside"
                                        />
                                        <Input
                                            variant="bordered"
                                            label="Load Count"
                                            labelPlacement="outside"
                                        />
                                    </div>
                                )}

                                <div className="flex justify-center items-center gap-4 mt-4" >
                                    <Button className="incidentSubmitBtn" type="submit" color="primary">
                                        Submit
                                    </Button>
                                    <Button color="danger" variant="flat" onClick={() => navigate('/manager/trip')}>
                                        Cancel
                                    </Button>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default TripForm;
