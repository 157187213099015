import React from "react";
import { Input, Checkbox, Button ,Select,SelectItem} from "@nextui-org/react";

const Roleaccess = () => {
  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <h1 className="report-heading">Role Permission</h1>
      <div className="px-4">
        <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <div className="form-container">
            <form>
              <div className="form-row mb-4">
              <Select
                    label="Role"
                    className="custom-select"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                  >
                    <SelectItem key=" Booking Agent" value=" Booking Agent">
                  Booking Agent
                    </SelectItem>
                    <SelectItem key="  Finance Manager" value="  Finance Manager">
                      Finance Manager
                    </SelectItem>
                  </Select>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Organization</div>
                <div className="form-row space-x-4">
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Department</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Role</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Dashboard</div>
                <div className="form-row space-x-4">
                  <Checkbox>View</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Accounts Receivable Report</div>
                <div className="form-row space-x-4">
                  <Checkbox>View</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Customer</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Contract</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Invoice</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Payment</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Trip</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Employee Details</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Employee Document</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Document Config</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Code Settings</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Country</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">State</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">City</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Taluk</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Role Access Config</div>
                <div className="form-row space-x-4">
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Vehicle Information</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Vehicle Loan</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Vehicle Insurance</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Vehicle Maintenance</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-col gap-1 font-bold">Vehicle Fuel Management</div>
                <div className="form-row space-x-4">
                  <Checkbox>Create</Checkbox>
                  <Checkbox>Edit</Checkbox>
                  <Checkbox>View</Checkbox>
                  <Checkbox>Delete</Checkbox>
                </div>
              </div>

              <div className="flex justify-center items-center gap-4 mt-4">
                <Button color="danger" variant="flat">
                  Cancel
                </Button>
                <Button color="primary">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roleaccess;
