import React, { useState,useRef } from 'react'
import { useTheme } from 'next-themes';
import DepartmentTable from '../components/baseComponent/DepartmentTable';
import DepartmentForm from '../components/baseComponent/DepartmentForm';
import FuelForm from '../components/baseComponent/FuelForm';
import FuelTable from '../components/baseComponent/FuelTable';
import { useNavigate, Route, Routes } from "react-router-dom";
const Fuel = () => {
  const { theme } = useTheme();
  
  const fileInputRef = useRef(null);

  const handleDownloadClick = () => {
    fileInputRef.current.click();
  };
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="rechart__custom-tooltip bg-black dark:bg-white">
          <p className="value text-sm m-0 text-white dark:text-black">{`${label} month no of leaves ${payload[0]?.value}`}</p>
        </div>
      );
    }

    return null;
  };

  const [isModelBox, setIsModelBox] = useState(false);
  const navigate = useNavigate();
  const [isModelBoxOpen, setIsModelBoxOpen] = useState(false);

  const handleModelBoxClick = () => {
    setIsModelBoxOpen(true);
    navigate("/manager/fuelform");
  };

  const handleCloseModal = () => {
    setIsModelBoxOpen(false);
    navigate("/manager/fuel"); 
  };


  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
        <h1 className="report-heading">Fuel Management</h1>
        <div className="graph-wrapper px-4">
      </div>
      <div className="px-4">
        <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl"style={{marginTop:'-50px' }} >
          <div className="flex justify-end items-center gap-4">
            <div className="header-wrapper">
              {/* <h1>Hotel List</h1> */}
            </div>
            <button className="py-2 px-3 bg-indigo-500 hover:bg-indigo-800 text-white text-xs font-semibold rounded-md shadow focus:outline-none" onClick={handleModelBoxClick}>
              Add Fuel
            </button>
            {/* <button className="py-2 px-3 bg-indigo-500 hover:bg-indigo-800 text-white text-xs font-semibold rounded-md shadow focus:outline-none"  onClick={handleDownloadClick} >
              Download From Excel
            </button> */}
            <input type='file' ref={fileInputRef} hidden></input>
          </div>
          <FuelTable />
        </div>
      </div>
      {isModelBoxOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="modal-close" onClick={handleCloseModal}>
              Close
            </button>
            <FuelForm onSubmit={handleCloseModal} />
          </div>
        </div>
      )}

    </div>
    
  )
}

export default Fuel
