import React, { useState, useRef  } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TimeInput,
  Input,
  DatePicker,
  Textarea,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";

const CodesettingForm = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/manager/code");
  };
  const formRef = useRef(null);

  const handleReset = () => {
      if (formRef.current) {
          formRef.current.reset();
      }
  };


  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <h1 className="report-heading">Code Setting</h1>
      <div className="px-4">
        <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div>
              <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                                    <Select
                                        label="Field Name"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="Invoice Id" value="Invoice Id">
                                            Invoice Id
                                        </SelectItem>
                                        <SelectItem key="Customer Id" value="Customer Id">
                                            Customer Id
                                        </SelectItem>
                                        <SelectItem key="Contract Id" value="Contract Id">
                                            Contract Id
                                        </SelectItem>
                                        <SelectItem key="Ticket Id" value="Ticket Id">
                                            Ticket Id
                                        </SelectItem>
                                        <SelectItem key="Employee Id" value="Employee Id">
                                            Employee Id
                                        </SelectItem>
                                        <SelectItem key="Voucher Id" value="Voucher Id">
                                            Voucher Id
                                        </SelectItem>
                                    </Select>

                                    <Input
                                        variant="bordered"
                                        label="Screen"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Prefix"
                                        labelPlacement="outside"
                                    />
                                </div>
                                <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                                    <Input
                                        variant="bordered"
                                        label="Series Starting From"
                                        labelPlacement="outside"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Separator"
                                        labelPlacement="outside"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Suffix"
                                        labelPlacement="outside"
                                    />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                                    <Input
                                        variant="bordered"
                                        label="Preview"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleReset}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: "#fff",
                                            border: "none",
                                            outline: "none",
                                            cursor: "pointer",
                                            padding: "0",
                                            marginLeft: "10px" // Adjust spacing as needed
                                        }}
                                    >
                                        <span style={{ marginRight: "5px" }}>Reset</span>
                                        <svg
                                            fill="#000000"
                                            height="24px"
                                            width="24px"
                                            version="1.1"
                                            id="Capa_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 489.645 489.645"
                                            xmlSpace="preserve"
                                        >
                                            <g>
                                                <path d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3
                                                    c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5
                                                    c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8
                                                    c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2
                                                    C414.856,432.511,548.256,314.811,460.656,132.911z"
                                                />
                                            </g>
                                        </svg>
                                    </button>
                                </div>

                <div className="flex justify-center items-center gap-4 mt-4" >
                  <Button className="incidentSubmitBtn" type="submit" color="primary">
                    Submit
                  </Button>
                  <Button color="danger" variant="flat" onClick={() => navigate('/manager/code')}>
                    Cancel
                  </Button>
                </div>

              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CodesettingForm;
