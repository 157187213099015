import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Input,
} from "@nextui-org/react";
import { getNames } from "country-list";
import { FaPlus, FaMinus } from "react-icons/fa";
import "./ModelForm.css";

const EmpdocForm = () => {
  const navigate = useNavigate();
  const [textFields, setTextFields] = useState([
    { id: Date.now(), isMandateEnabled: false, isUniqueEnabled: false },
  ]);
  const [dateFields, setDateFields] = useState([
    { id: Date.now(), isMandateEnabled: false, isUniqueEnabled: false },
  ]);

  const addTextField = () => {
    setTextFields([
      ...textFields,
      { id: Date.now(), isMandateEnabled: false, isUniqueEnabled: false },
    ]);
  };

  const removeTextField = (id) => {
    setTextFields(textFields.filter((field) => field.id !== id));
  };

  const addDateField = () => {
    setDateFields([
      ...dateFields,
      { id: Date.now(), isMandateEnabled: false, isUniqueEnabled: false },
    ]);
  };

  const removeDateField = (id) => {
    setDateFields(dateFields.filter((field) => field.id !== id));
  };

  const handleToggleMandate = (id, type) => {
    if (type === "text") {
      setTextFields(
        textFields.map((field) =>
          field.id === id
            ? { ...field, isMandateEnabled: !field.isMandateEnabled }
            : field
        )
      );
    } else {
      setDateFields(
        dateFields.map((field) =>
          field.id === id
            ? { ...field, isMandateEnabled: !field.isMandateEnabled }
            : field
        )
      );
    }
  };

  const handleToggleUnique = (id, type) => {
    if (type === "text") {
      setTextFields(
        textFields.map((field) =>
          field.id === id
            ? { ...field, isUniqueEnabled: !field.isUniqueEnabled }
            : field
        )
      );
    } else {
      setDateFields(
        dateFields.map((field) =>
          field.id === id
            ? { ...field, isUniqueEnabled: !field.isUniqueEnabled }
            : field
        )
      );
    }
  };

  const toggleSwitch = (enabled, onClick) => (
    <div
      style={{
        display: "inline-block",
        marginRight: "20px",
        verticalAlign: "middle",
        cursor: "pointer",
        backgroundColor: enabled ? "#4caf50" : "#ccc",
        borderRadius: "20px",
        width: "40px",
        height: "20px",
        position: "relative",
        transition: "background-color 0.3s",
      }}
      onClick={onClick}
    >
      <div
        style={{
          position: "absolute",
          top: "2px",
          left: enabled ? "20px" : "2px",
          width: "16px",
          height: "16px",
          backgroundColor: "white",
          borderRadius: "50%",
          transition: "transform 0.3s",
        }}
      ></div>
    </div>
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/manager/emp_doc");
  };

  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <h1 className="report-heading">Add Employee Document Details</h1>
      <div className="px-4">
        <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div>
              <div className="flex gap-2 items-center">
                  <Input
                    variant="bordered"
                    label="Country"
                    labelPlacement="outside"
                  />
                   <Input
                    variant="bordered"
                    label="Document Name"
                    labelPlacement="outside"
                  />
                </div>

                <div className="flex flex-col gap-1 my-3">Text Field</div>

                {textFields.map((field, index) => (
                  <div
                    key={field.id}
                    className="form-row"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Input
                      variant="bordered"
                      label="Field Name"
                      labelPlacement="outside"
                      style={{ flex: 1 }}
                    />
                    <Input
                      variant="bordered"
                      label="Condition"
                      labelPlacement="outside"
                      style={{ flex: 1 }}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label style={{ marginRight: "10px" }}>Is Mandate:</label>
                      {toggleSwitch(field.isMandateEnabled, () =>
                        handleToggleMandate(field.id, "text")
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "20px",
                      }}
                    >
                      <label style={{ marginRight: "-10px" }}>Is Unique:</label>
                      {toggleSwitch(field.isUniqueEnabled, () =>
                        handleToggleUnique(field.id, "text")
                      )}
                      <button
                        type="button"
                        className="bg-green-500 text-white px-3 py-1 rounded-md text-lg"
                        onClick={
                          index === textFields.length - 1
                            ? addTextField
                            : () => removeTextField(field.id)
                        }
                        style={{ marginLeft: "10px" }}
                      >
                        {index === textFields.length - 1 ? <FaPlus /> : <FaMinus />}
                      </button>
                    </div>
                  </div>
                ))}

                <div className="flex flex-col gap-1 my-3">Date Field</div>

                {dateFields.map((field, index) => (
                  <div
                    key={field.id}
                    className="form-row"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Input
                      variant="bordered"
                      label="Field Name"
                      labelPlacement="outside"
                      style={{ flex: 1 }}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label style={{ marginRight: "10px" }}>Is Mandate:</label>
                      {toggleSwitch(field.isMandateEnabled, () =>
                        handleToggleMandate(field.id, "date")
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                    >
                      <label style={{ marginRight: "10px" }}>Is Unique:</label>
                      {toggleSwitch(field.isUniqueEnabled, () =>
                        handleToggleUnique(field.id, "date")
                      )}
                      <button
                        type="button"
                        className="bg-green-500 text-white px-3 py-1 rounded-md text-lg"
                        onClick={
                          index === dateFields.length - 1
                            ? addDateField
                            : () => removeDateField(field.id)
                        }
                        style={{ marginLeft: "10px" }}
                      >
                        {index === dateFields.length - 1 ? <FaPlus /> : <FaMinus />}
                      </button>
                    </div>
                  </div>
                ))}

                <div className="flex justify-center items-center gap-4 mt-4">
                  <Button
                    className="incidentSubmitBtn"
                    type="submit"
                    color="primary"
                  >
                    Submit
                  </Button>
                  <Button
                    color="danger"
                    variant="flat"
                    onClick={() => navigate("/manager/emp_doc")}
                  >
                    Cancel
                  </Button>
                </div>

              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default EmpdocForm;
