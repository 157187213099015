import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TimeInput,
  Input,
  DatePicker,
  Textarea,
  Select,
  SelectItem,
  Checkbox
} from "@nextui-org/react";
import "./ModelForm.css";

const StateForm = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/manager/state");
  };

  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <h1 className="report-heading">Add State Details</h1>
      <div className="px-4">
        <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div>
              <div className="form-row">
                  <Select
                    label="Country Name"
                    className="custom-select"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                  >
                    <SelectItem key="India" value="India">
                      India
                    </SelectItem>
                  </Select>
                  <Input
                    variant="bordered"
                    label="State Name"
                    labelPlacement="outside"
                  />

                  <Checkbox>
                    City
                  </Checkbox>
                </div>

                <div className="flex justify-center items-center gap-4 mt-4" >
                  <Button className="incidentSubmitBtn" type="submit" color="primary">
                    Submit
                  </Button>
                  <Button color="danger" variant="flat" onClick={() => navigate('/manager/state')}>
                    Cancel
                  </Button>
                </div>

              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default StateForm;
