// import React, { useState, useEffect } from 'react';
// import { ThemeSwitcher } from '../baseComponent/themeSwiter';
// import NotificationPopup from '../baseComponent/Notification';
// import Avatar from '../baseComponent/ProfileAvatar';
// import { useNavigate } from 'react-router-dom';
// // import { removeLogger } from './Logger';

// const Header = ({ setSideBar }) => {
//   const [isFullScreen, setIsFullScreen] = useState(false);
//   const [isPopupVisible, setIsPopupVisible] = useState(false);
//   const [role, setRole] = useState('');

//   const navigate = useNavigate();

//   useEffect(() => {
//     const userRole = localStorage.getItem('loggedInUser');
//     if (userRole) {
//       setRole(userRole);
//     }
//   }, []);

//   const handleLogout = () => {
//     // Navigate to the login screen
//     localStorage.clear();
//     navigate('/');
//   };

//   const toggleFullScreen = () => {
//     if (!isFullScreen) {
//       // Request full-screen mode
//       if (document.documentElement.requestFullscreen) {
//         document.documentElement.requestFullscreen();
//       } else if (document.documentElement.mozRequestFullScreen) { // Firefox
//         document.documentElement.mozRequestFullScreen();
//       } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari, and Opera
//         document.documentElement.webkitRequestFullscreen();
//       } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
//         document.documentElement.msRequestFullscreen();
//       }
//     } else {
//       // Exit full-screen mode
//       if (document.exitFullscreen) {
//         document.exitFullscreen();
//       } else if (document.mozCancelFullScreen) {
//         document.mozCancelFullScreen();
//       } else if (document.webkitExitFullscreen) {
//         document.webkitExitFullscreen();
//       } else if (document.msExitFullscreen) {
//         document.msExitFullscreen();
//       }
//     }

//     // Toggle the state
//     setIsFullScreen(!isFullScreen);
//   };

//   const togglePopup = () => {
//     setIsPopupVisible(!isPopupVisible);
//   };

//   // Array of logo objects with URLs and names
//   const logos = [
//     // { url: '/images/logo6.png', name: 'EDMS', link: 'http://10.10.1.100:2123/' },
//     // { url: '/images/logo4.png', name: 'SoftEnroute', link: 'http://softenroute.corestructuralconsultants.com/' },
//     // { url: '/images/logo3.png', name: 'TriMS', link: 'http://10.10.1.100:5173/home' },
//     // { url: '/images/logo5.png', name: 'PPE', link: 'www.softenroute.app/safety' },
//     { url: '/images/logo2.png', name: 'HRMS', link: 'http://10.10.1.100:3011/hrms/auth' },
//     // { url: '/images/logo1.png', name: 'Expenses' },
//   ];

//   return (
//     <header className='main-header'>
//       <nav>
//         <div className="header-inner">
//           <div className="hamburger-icon" onClick={() => setSideBar()}>
//             <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
//               <path d="M5.33334 9.33334H9.33334M26.6667 9.33334H14.6667M26.6667 22.6667H22.6667M5.33334 22.6667H17.3333M5.33334 16H26.6667" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
//             </svg>
//           </div>
//           <div className="menu-items admin cursor-pointer">
//             {role === 'admin' && 'Welcome Admin'}

//           </div>


//           <div className="menu-items-wrapper flex gap-x-6 items-center">
//             <div className="menu-items cursor-pointer" onClick={toggleFullScreen}>
//               <div className="icon">
//                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <path d="M10 22C6.229 22 4.343 22 3.172 20.828C2 19.657 2 17.771 2 14M10 2C6.229 2 4.343 2 3.172 3.172C2.93104 3.41292 2.73257 3.69287 2.585 4M2 10C2 9.265 2 8.6 2.009 8M14 2C17.771 2 19.657 2 20.828 3.172C22 4.343 22 6.229 22 10M14 22C17.771 22 19.657 22 20.828 20.828C21.069 20.5871 21.2674 20.3071 21.415 20M22 14C22 14.736 22 15.4 21.991 16" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
//                 </svg>
//               </div>
//             </div>

//             <div className="menu-items cursor-pointer" onClick={togglePopup}>
//               <div className="icon">
//                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <circle cx="4" cy="4" r="2" fill="black" />
//                   <circle cx="12" cy="4" r="2" fill="black" />
//                   <circle cx="20" cy="4" r="2" fill="black" />
//                   <circle cx="4" cy="12" r="2" fill="black" />
//                   <circle cx="12" cy="12" r="2" fill="black" />
//                   <circle cx="20" cy="12" r="2" fill="black" />
//                   <circle cx="4" cy="20" r="2" fill="black" />
//                   <circle cx="12" cy="20" r="2" fill="black" />
//                   <circle cx="20" cy="20" r="2" fill="black" />
//                 </svg>
//               </div>
//             </div>

//             {isPopupVisible && (
//               <div className="popup-card absolute top-14 right-0 mt-2 w-60 p-4 bg-white border border-gray-300 shadow-md rounded-lg">
//                 {/* <h3 className="text-lg font-semibold">Applications</h3> */}
//                 <div className="grid grid-cols-1 gap-4 mt-4">
//                   {logos.map((logo, index) => (
//                     <a key={index} href={logo.link}
//                       target="_blank"
//                       rel="noopener noreferrer" 
//                       className="flex flex-col items-center">
//                       <img src={logo.url} alt={logo.name} className="h-12 w-12 object-contain" />
//                       <p className="text-sm mt-2">{logo.name}</p>
//                     </a>
//                   ))}
//                 </div>
//               </div>
//             )}

//             <ThemeSwitcher />



//             <div className="menu-items cursor-pointer" onClick={handleLogout}>
//               Logout
//             </div>
//             {/* <Avatar sizeClass="h-8 w-8 text-base" radius="rounded-full" containerClassName="ring-2 ring-purple-500 ring-offset-2 ring-offset-slate-50 dark:ring-offset-slate-900" /> */}
//           </div>
//         </div>
//       </nav>
//     </header>
//   );
// };
// export default Header;

import React, { useState } from 'react';
import { ThemeSwitcher } from '../baseComponent/themeSwiter';
import { useNavigate } from 'react-router-dom';

const Header = ({ setSideBar }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isProfileDropdownVisible, setIsProfileDropdownVisible] = useState(false);
  const [isPasswordUpdateVisible, setIsPasswordUpdateVisible] = useState(false);
  const [role, setRole] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopupVisible1, setIsPopupVisible1] = useState(false);


  // State variables for password update form
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useNavigate();

  // Simulating the role retrieval from localStorage
  useState(() => {
    const userRole = localStorage.getItem('loggedInUser');
    if (userRole) {
      setRole(userRole);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const logos = [
    // { url: '/images/logo6.png', name: 'EDMS', link: 'http://10.10.1.100:2123/' },
    { url: '/images/logo2.png', name: 'HRMS', link: 'http://10.10.1.100:3011/hrms/auth' },
    // { url: '/images/logo1.png', name: 'Expenses' },
  ];
  
  const menuItems = [
    {
      name: 'Organization Details',
      path: '/manager/org/',
      icon: 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525" />
        </svg>
      ,
    },
    {
      name: 'Department Details',
      path: '/manager/Department/',
      icon: 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
        </svg>
      ,
    },
  ];


  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const togglePopupgear = () => {
    setIsPopupVisible1(!isPopupVisible1);
  };


  const toggleFullScreen = () => {
    if (!isFullScreen) {
      document.documentElement.requestFullscreen?.();
    } else {
      document.exitFullscreen?.();
    }
    setIsFullScreen(!isFullScreen);
  };

  const toggleProfileDropdown = () => {
    setIsProfileDropdownVisible(!isProfileDropdownVisible);
  };

  const togglePasswordUpdate = () => {
    setIsPasswordUpdateVisible(!isPasswordUpdateVisible);
  };

  const handleEditProfile = () => {
    navigate('/EmployeeForm'); // Navigate to EmployeeForm.jsx
  };

  const handlePasswordUpdate = (e) => {
    e.preventDefault();
    // Implement your password update logic here
    // For demonstration, just clear the form and close the popup
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setIsPasswordUpdateVisible(false);
  };

  return (
    <header className="main-header">
      <nav>
        <div className="header-inner">
          <div className="hamburger-icon" onClick={() => setSideBar()}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.33334 9.33334H9.33334M26.6667 9.33334H14.6667M26.6667 22.6667H22.6667M5.33334 22.6667H17.3333M5.33334 16H26.6667" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
          </div>
          <div className="menu-items admin cursor-pointer" style={{ color: 'purple', fontSize: '20px' }}>
            {role === 'manager' && 'Welcome Trims'}
          </div>

          <div className="menu-items-wrapper flex gap-x-6 items-center">
            <div className="menu-items cursor-pointer" onClick={toggleFullScreen}>
              <div className="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 22C6.229 22 4.343 22 3.172 20.828C2 19.657 2 17.771 2 14M10 2C6.229 2 4.343 2 3.172 3.172C2.93104 3.41292 2.73257 3.69287 2.585 4M2 10C2 9.265 2 8.6 2.009 8M14 2C17.771 2 19.657 2 20.828 3.172C22 4.343 22 6.229 22 10M14 22C17.771 22 19.657 22 20.828 20.828C21.069 20.5871 21.2674 20.3071 21.415 20M22 14C22 14.736 22 15.4 21.991 16" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </div>
            </div>

            <div className="menu-items cursor-pointer" onClick={toggleProfileDropdown}>
              <div className="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="10" stroke="black" strokeWidth="2" />
                  <circle cx="12" cy="8" r="4" fill="black" />
                </svg>
              </div>
            </div>
            {isProfileDropdownVisible && (
              <div className="dropdown-profile absolute top-14 right-0 mt-2 w-64 p-4 bg-white border border-gray-300 shadow-md rounded-lg">
                <div className="dropdown-content-heading text-center mb-4">
                  <img
                    src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Image-180x180.png"
                    alt="Profile"
                    className="h-12 w-12 rounded-full mx-auto"
                  />
                  <div className="font-semibold mt-2">Personalization</div>
                  <div className="text-gray-600">Security</div>
                  ------------------------------------------
                  <div className="text-gray-600">Documentation</div>
                  <div className="text-gray-600">Support</div>
                  -------------------------------------------

                  <div className="text-gray-600"></div>
                </div>
                <div className="dropdown-content-body">
                  <div className="flex flex-col items-center gap-2">
                    {/* <button
                      type="button"
                      className="btn bg-gradient-dark text-black w-full"
                      onClick={handleEditProfile}
                    >
                      Edit Profile
                    </button> */}
                    {/* <button
                      type="button"
                      className="btn bg-gradient-dark text-black w-full"
                      onClick={togglePasswordUpdate}
                    >
                      Update Password
                    </button> */}
                    {isPasswordUpdateVisible && (
                      <div className="password-update-popup">
                        <h6 className="change-password-heading text-center">Change Password</h6>
                        <i className="fa fa-arrow-left icon" aria-hidden="true" onClick={togglePasswordUpdate}></i>
                        <form onSubmit={handlePasswordUpdate}>
                          <div className="form-group">
                            <label>Old Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={oldPassword}
                              onChange={(e) => setOldPassword(e.target.value)}
                              placeholder="Old Password"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label>New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              placeholder="New Password"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label>Confirm Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              placeholder="Confirm Password"
                              required
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-flat m-b-10 m-t-10 bg-gradient-success font-weight-bolder text-center"
                            style={{ display: 'block', margin: 'auto' }}
                          >
                            Update
                          </button>
                        </form>
                      </div>
                    )}
                    <button
                      type="button"
                      className="btn bg-gradient-dark text-black border border-blue-500 w-full"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            )}
            

            <div className="menu-items cursor-pointer" onClick={togglePopupgear} >
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
              </div>
            </div>

            {isPopupVisible1 && (
              <div className="popup-card absolute top-14 right-0 mt-2 w-60 p-4 bg-white border border-gray-300 shadow-md rounded-lg">
                {/* <h3 className="text-lg font-semibold">Applications</h3> */}
                <div className="grid grid-cols-1 gap-4 mt-4">
                  {menuItems.map((val, index) => (
                    <a key={index} href={val.path}
                      // target="_blank"
                      rel="noopener noreferrer"
                      style={{display : "flex" , flexDirection : "row" , gap : "5px" , alignItems : "center" , justifyContent : "center"}}
                      className="flex flex-col items-center">
                      {val.icon}
                      <p className="text-sm mt-2">{val.name}</p>
                    </a>
                  ))}
                </div>
              </div>
            )}

            <div className="menu-items cursor-pointer" onClick={togglePopup}>
              <div className="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="4" cy="4" r="2" fill="black" />
                  <circle cx="12" cy="4" r="2" fill="black" />
                  <circle cx="20" cy="4" r="2" fill="black" />
                  <circle cx="4" cy="12" r="2" fill="black" />
                  <circle cx="12" cy="12" r="2" fill="black" />
                  <circle cx="20" cy="12" r="2" fill="black" />
                  <circle cx="4" cy="20" r="2" fill="black" />
                  <circle cx="12" cy="20" r="2" fill="black" />
                  <circle cx="20" cy="20" r="2" fill="black" />
                </svg>
              </div>
            </div>

            {isPopupVisible && (
              <div className="popup-card absolute top-14 right-0 mt-2 w-60 p-4 bg-white border border-gray-300 shadow-md rounded-lg">
                {/* <h3 className="text-lg font-semibold">Applications</h3> */}
                <div className="grid grid-cols-1 gap-4 mt-4">
                  {logos.map((logo, index) => (
                    <a key={index} href={logo.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center">
                      <img src={logo.url} alt={logo.name} className="h-12 w-12 object-contain" />
                      <p className="text-sm mt-2">{logo.name}</p>
                    </a>
                  ))}
                </div>
              </div>
            )}

          

            <ThemeSwitcher />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;

