import React, { useState } from 'react'
import { useTheme } from 'next-themes';
import PaymentTable from '../components/baseComponent/PaymentTable';
import PaymentForm from '../components/baseComponent/PaymentForm';

import { useNavigate, Route, Routes } from "react-router-dom";



const Payment = () => {
  const { theme } = useTheme();

  const navigate = useNavigate();
  const [isModelBoxOpen, setIsModelBoxOpen] = useState(false);

  const handleModelBoxClick = () => {
    setIsModelBoxOpen(true);
    navigate("/manager/paymentform");
  };

  const handleCloseModal = () => {
    setIsModelBoxOpen(false);
    navigate("/manager/payment"); 
  };


  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="rechart__custom-tooltip bg-black dark:bg-white">
          <p className="value text-sm m-0 text-white dark:text-black">{`${label} month no of leaves ${payload[0]?.value}`}</p>
        </div>
      );
    }

    return null;
  };

  const [isModelBox, setIsModelBox] = useState(false);

 
  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
        <h1 className="report-heading">Payment Information</h1>
        <div className="graph-wrapper px-4">
      </div>
      <div className="px-4">
        <div className="leave-apply__wrapper p-4 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl" style={{marginTop:'-50px' }}>
          <div className="flex justify-between items-center gap-4">
            <div className="header-wrapper">
              {/* <h1>Customer Details</h1> */}
            </div>
            <button className="py-2 px-3 bg-indigo-500 hover:bg-indigo-800 text-white text-xs font-semibold rounded-md shadow focus:outline-none" onClick={handleModelBoxClick}>
              Add Payment
            </button>
          </div>
          <PaymentTable/>
        </div>
      </div>
      {isModelBoxOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="modal-close" onClick={handleCloseModal}>
              Close
            </button>
            <PaymentForm onSubmit={handleCloseModal} />
          </div>
        </div>
      )}
    </div>
    
  )
}

export default Payment
