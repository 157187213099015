import React, { useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const Dashboard = () => {
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState('2023');
  const [selectedMonth, setSelectedMonth] = useState('January');
  const [selectedYearClient, setSelectedYearClient] = useState('2023');
  const [selectedMonthClient, setSelectedMonthClient] = useState('January');

  const years = ['2022', '2023', '2024'];
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChangeClient = (event) => {
    setSelectedYearClient(event.target.value);
  };

  const handleMonthChangeClient = (event) => {
    setSelectedMonthClient(event.target.value);
  };

  const pieData = {
    labels: ['Not Started', 'Started', 'In Progress'],
    datasets: [
      {
        label: 'Trip Progress',
        data: [18, 49, 9],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  const barData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Revenue',
        data: [12000, 19000, 30000, 50000, 20000, 30000, 45000, 32000, 41000, 32000, 40000, 50000],
        backgroundColor: '#36A2EB',
      },
    ],
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: "center", marginBottom: '10px' }}>
        <div style={{ background: 'white', marginRight: "10px", padding: '20px', borderRadius: '10px', textAlign: 'center', width: '200px' }}>
          <h3 style={{ fontWeight: 'bold' }}>Insurance</h3>
          {/* <p>12</p> */}
          <div className="col-5">
            <p style={{ color: 'darkblue' }}>Total: 0</p>
          </div>
          <div className="col-7">
            <>
              <p style={{ color: 'red', marginBottom: '6px' }}>Tomorrow: </p>
              <p style={{ color: 'red', marginBottom: '6px' }}>7 days: 0</p>
              <p style={{ color: 'brown', marginBottom: '6px' }}>15 days: 0</p>
              <p style={{ color: 'green', marginBottom: '6px' }}>30 days: 0</p>
            </>
          </div>
        </div>

        <div style={{ background: 'white', padding: '20px', marginRight: "10px", borderRadius: '10px', textAlign: 'center', width: '200px' }}>
          <h3 style={{ fontWeight: 'bold' }}>Pending Loan</h3>
          <div className="col-5">
            <p style={{ color: 'darkblue' }}>Total: 0</p>
          </div>
          <div className="col-7">
            <>
              <p style={{ color: 'red', marginBottom: '6px' }}>Tomorrow: </p>
              <p style={{ color: 'red', marginBottom: '6px' }}>7 days: 0</p>
              <p style={{ color: 'brown', marginBottom: '6px' }}>15 days: 0</p>
              <p style={{ color: 'green', marginBottom: '6px' }}>30 days: 0</p>
            </>
          </div>
        </div>
        <div style={{ background: 'white', padding: '20px', borderRadius: '10px', marginRight: "10px", textAlign: 'center', width: '200px' }}>
          <h3 style={{ fontWeight: 'bold' }}>Passport</h3>
          <div className="col-5">
            <p style={{ color: 'darkblue' }}>Total: 0</p>
          </div>
          <div className="col-7">
            <>
              <p style={{ color: 'red', marginBottom: '6px' }}>Tomorrow: </p>
              <p style={{ color: 'red', marginBottom: '6px' }}>7 days: 0</p>
              <p style={{ color: 'brown', marginBottom: '6px' }}>15 days: 0</p>
              <p style={{ color: 'green', marginBottom: '6px' }}>30 days: 0</p>
            </>
          </div>
        </div>
        <div style={{ background: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center', marginRight: "10px", width: '200px' }}>
          <h3 style={{ fontWeight: 'bold' }}>Visa Expiry</h3>
          <div className="col-5">
            <p style={{ color: 'darkblue' }}>Total: 0</p>
          </div>
          <div className="col-7">
            <>
              <p style={{ color: 'red', marginBottom: '6px' }}>Tomorrow: </p>
              <p style={{ color: 'red', marginBottom: '6px' }}>7 days: 0</p>
              <p style={{ color: 'brown', marginBottom: '6px' }}>15 days: 0</p>
              <p style={{ color: 'green', marginBottom: '6px' }}>30 days: 0</p>
            </>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '20%', marginRight: '20px' }}>
          <div style={{ background: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center', marginBottom: '10px' }}>
            <h3 style={{ fontWeight: 'bold' }}>Total Revenue</h3>
            <p style={{ color: 'blue' }}>180K</p>
            <div className="col-5">
              <p style={{ color: 'darkblue' }}>Top Cilents</p>
            </div>
            <>
              <p style={{ color: 'red' }}> Lum Charg Pvt Ltd: 80k</p>
              <p style={{ color: 'red' }}>BCC PNAC: 50k</p>
              <p style={{ color: 'red' }}>Cilent Abc: 10k</p>
             

            </>
          </div>
          <div style={{ background: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center', marginBottom: '10px' }}>
            <h3 style={{ fontWeight: 'bold' }}>Accounts Receivable</h3>

            <div className="col-5">
              <p style={{ color: 'darkblue' }}>Amount: 16000</p>
            </div>
            <>
              <p style={{ color: 'red' }}> 90: 0</p>
              <p style={{ color: 'red' }}>60 to 90: 0</p>
              <p style={{ color: 'brown' }}>30 to 60: 6</p>
              <p style={{ color: 'green' }}>7 to 30: 5</p>
              <p style={{ color: 'green' }}>0 to 7: 1</p>

            </>
          </div>
          <div style={{ background: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center', marginBottom: '10px' }}>
            <h3 style={{ fontWeight: 'bold' }}>Trips Today</h3>
            <div className="col-7">
              <>
                <p style={{ color: 'blue' }}>Trips Created Day/Load: 6/2</p>
                <p style={{ color: 'red' }}>Not Started: 1</p>
                <p style={{ color: 'brown' }}>In Progress: 5</p>
                <p style={{ color: 'green' }}>Trips Completed: 0</p>
                <p style={{ color: 'red' }}>Trips Aborted: 0</p>

              </>
            </div>
          </div>
        </div>
        <div style={{ width: '50%', border: '1px solid #ccc', padding: '20px', borderRadius: '10px', fontWeight: 'bold', marginRight: '20px' }}>
          <h3>Revenue Chart</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <select value={selectedYear} onChange={handleYearChange} style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <select value={selectedMonth} onChange={handleMonthChange} style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}>
              {months.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          <Bar data={barData} />
        </div>
        <div style={{ width: '30%', border: '1px solid #ccc', padding: '20px', borderRadius: '10px', fontWeight: 'bold' }}>
          <h3>Client Wise Distribution</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <select value={selectedYearClient} onChange={handleYearChangeClient} style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <select value={selectedMonthClient} onChange={handleMonthChangeClient} style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}>
              {months.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          <Pie data={pieData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
