
//Customer

const columnsCustomer = [
  
  { name: "Customer Id", uid: "id", sortable: true },
  { name: "Customer Name", uid: "customer_name", sortable: true },
  { name: "Email", uid: "mail_id" },
  { name: "Contact Number", uid: "contact_no" },
  { name: "Gst No", uid: "gst_no", sortable: true },
  { name: "Country", uid: "country" },
  { name: "Action", uid: "actions" },
];

const customerList = [
  {
    id: 1,
    id: "CUST-001",
    customer_name: "John Doe",
    mail_id: "johndoe@example.com",
    contact_no: "0987654321",
    gst_no: "07ABCDE5678G1Z6",
    country: "India",

  },

  {
    id: 1,
    id: "CUST-001",
    customer_name: "Emily Davis",
    mail_id: "emily.davis@example.com",
    contact_no: "987654321",
    gst_no: "27ABCDE1234F1Z5",
    country: "France",

  },
  
];

//Contract

const columnsContract = [
  
  { name: "Customer Name", uid: "customer_name", sortable: true },
  { name: "Contract Id", uid: "contract_id", sortable: true },
  { name: "Customer Reg No", uid: "cus_reg_no" },
  { name: "Contract Date", uid: "cont_date" },
  { name: "Start Date", uid: "start_date", sortable: true },
  { name: "End Date", uid: "end_date" },
  { name: "Amount Per Trip", uid: "amount_trip" },
  { name: "Amount Per Load", uid: "amount_load" },
  { name: "OT Amount", uid: "ot_amt" },
  { name: "Billing Cycle", uid: "billing_cycle" },
  { name: "Payment Terms", uid: "pay_term" },
  { name: "Action", uid: "actions" },
];

const contractList = [
  {
    id: 1,
    customer_name: "John Doe",
    contract_id: "CONT-001",
    cus_reg_no: "REG-1001",
    cont_date: "05/04/2024",
    start_date: "05/04/2024",
    end_date: "04/04/2025",
    amount_trip: "200",
    amount_load: "80",
    ot_amt: "50",
    billing_cycle: "Monthly",
    pay_term: "NET 30",

  },

  {
    id: 2,
    customer_name: "Emily Davis",
    contract_id: "CONT-002",
    cus_reg_no: "REG-1501",
    cont_date: "20/01/2024",
    start_date: "20/01/2024",
    end_date: "19/01/2025",
    amount_trip: "300",
    amount_load: "250",
    ot_amt: "80",
    billing_cycle: "Weekly",
    pay_term: "NET 10",

  },

];

//Role

const Rolecolumn =[
  {name: "DEPARTMENT NAME", uid: "department_name", sortable: true},
  {name: "ROLE", uid: "role_name", sortable: true},
  {name: "ACTIONS", uid: "actions"},
]
const Rolelist =[
  {
    id: 1,
    department_name: "Customer Service and Support Department",
    role_name: "Booking Agent",
  },
  {
    id: 2,
    department_name: "Finance and Budgeting Department",
    role_name: "Finance Manager",
  },
]

//Department

const Departmentcolumn =[
  {name: "Department Code", uid: "dep_code", sortable: true},
  {name: "Department Name", uid: "dep_name", sortable: true},
  {name: "ACTIONS", uid: "actions"},
]
 
const Departmentlist =[
  {
    id: 1,
    dep_code: "001",
    dep_name: "Customer Service and Support Department",
  },
  {
    id: 2,
    dep_code: "002",
    dep_name: "Finance and Budgeting Department",
  },
 
]

//invoice

const Invoicecolumn =[
  {name: "Invoice No", uid: "invoice_no", sortable: true},
  {name: "Invoice Date", uid: "invoice_date", sortable: true},
  {name: "Customer Name", uid: "cus_name", sortable: true},
  {name: "Due Date", uid: "due_date", sortable: true},
  {name: "ACTIONS", uid: "actions"},
]
 
const Invoicelist =[
  {
    id: 1,
    invoice_no: "INV001",
    invoice_date: "10/03/2024",
    cus_name: "Emily Davis",
    due_date: "20/04/2024",

  },
  
]

//Employee

const columnsEmployee = [
  
  { name: "Employee Id", uid: "id", sortable: true },
  { name: "First Name", uid: "first_name", sortable: true },
  { name: "Last Name", uid: "last_name" },
  { name: "Email ID", uid: "mail_id" },
  { name: "Date of Birth", uid: "dob", sortable: true },
  { name: "Address", uid: "address" },
  { name: "Country", uid: "country" },
  { name: "Contact Number", uid: "number" },
  { name: "Role", uid: "role" },
  { name: "Action", uid: "actions" },
];

const employeeList = [
  {
    id: 1,
    id: "EMP-001",
    first_name: "John",
    last_name: "Peter",
    mail_id: "johnpeter@example.com",
    dob: "13/09/1997",
    address: "5/187, Selaiyur",
    country: "India",
    number: "9870765434",
    role: "Finance Manager",

  },
 
];


//payment

const columnsPayment = [
  
  { name: "Voucher Id", uid: "id", sortable: true },
  { name: "Payment Date", uid: "pay_date", sortable: true },
  { name: "Customer Name", uid: "cus_name" },
  { name: "Payment Method", uid: "pay_method" },
  { name: "Recevied Amount", uid: "receive_amt", sortable: true },
  { name: "Outstanding Amount", uid: "out_amt" },
  { name: "Action", uid: "actions" },
];

const paymentList = [
  {
    id: 1,
    id: "VOC-001",
    pay_date: "18/05/2024",
    cus_name: "John Doe",
    pay_method: "Cash",
    receive_amt: "5000",
    out_amt: "5000",

  },
 
];

//Organization

const columnsOrganization = [
  
  { name: "Reg No", uid: "reg_no", sortable: true },
  { name: "Fiscal Year", uid: "fis_year", sortable: true },
  { name: "Location", uid: "location" },
  { name: "Address", uid: "address" },
  { name: "Email", uid: "email", sortable: true },
  { name: "Mobile No", uid: "mob_no" },
  { name: "Action", uid: "actions" },
];

const organizationList = [
  {
    id: 1,
    reg_no: "11080",
    fis_year: "January-December",
    location: "India",
    address: "Chennai",
    email: "kkb@gmail.com",
    mob_no: "877468398",

  },
 
];

//Trip

//Organization

const columnsTrip = [
  
  { name: "Ticket Number", uid: "tic_num", sortable: true },
  { name: "Vehicle Reg No", uid: "veh", sortable: true },
  { name: "Driver Name", uid: "dri_name" },
  { name: "Trip Date", uid: "trip_date" },
  { name: "Customer Name", uid: "cus_name", sortable: true },
  { name: "Material", uid: "material" },
  { name: "Waiting Time", uid: "waiting_time" },
  { name: "Trip Status", uid: "trip_status", sortable: true },
  { name: "Trip Type", uid: "trip_type" },
  { name: "Action", uid: "actions" },
];

const tripList = [
  {
    id: 1,
    tic_num: "TIC11080",
    veh: "TN80AK5580",
    dri_name: "Eniyavan",
    trip_date: "13/04/2024",
    cus_name: "Emily Davis",
    material: "Solid",
    waiting_time: "2",
    trip_status: "Started",
    trip_type: "Day Trip",

  },
 
];

//codesetting

const columnsCodesetting = [
  
  { name: "Screen Name", uid: "screen_name", sortable: true },
  { name: "Prefix", uid: "prefix", sortable: true },
  { name: "Series", uid: "series" },
  { name: "Suffix", uid: "suffix" },
  { name: "Preview", uid: "preview", sortable: true },
  { name: "Action", uid: "actions" },
];


const codesettingList = [
  {
    id: 1,
    screen_name: "Customer Id",
    prefix: "CUS",
    series: "001",
    suffix: "KKB",
    preview: "CUS/KKB/001",
    
  },
 
];

//documentconfig

const columnsEmpdocconfig = [
  
  { name: "Country", uid: "country", sortable: true },
  { name: "Document Name", uid: "doc_name", sortable: true },
  { name: "Text Field", uid: "text_field" },
  { name: "Date Field", uid: "date_field" },
  { name: "Action", uid: "actions" },
];


const EmpdocconfigList = [
  {
    id: 1,
    country: "USA",
    doc_name: "Contract Agreement",
    text_field: "Yes",
    date_field: "Yes",
    
  },
 
];

//employeedocument

const columnsEmpdoc = [
  
  { name: "Employee Id", uid: "emp_id", sortable: true },
  { name: "Employee Name", uid: "emp_name", sortable: true },
  { name: "Department", uid: "dep" },
  { name: "Role", uid: "role" },
  { name: "Document Name", uid: "doc_name" },
  { name: "Action", uid: "actions" },
];


const EmpdocList = [
  {
    id: 1,
    emp_id: "EMP001",
    emp_name: "Davis",
    dep: "Finance and Budgeting Department",
    role: "Finance Manager",
    doc_name: "Passport",
  },
 
];


//Country


const columnsCountry = [
  
  { name: "Country Name", uid: "coun_name", sortable: true },
  { name: "Country Code", uid: "coun_code", sortable: true },
  { name: "Currency", uid: "currency" },
  { name: "Mobile Number Limit", uid: "mob_lmt" },
  { name: "GST No Limit", uid: "gst_lmt" },
  { name: "Action", uid: "actions" },
];


const CountryList = [
  {
    id: 1,
    coun_name: "India",
    coun_code: "+91",
    currency: "$",
    mob_lmt: "10",
    gst_lmt: "15",
  },
 
];


//State


const columnsState = [
  
  { name: "Country Name", uid: "coun_name", sortable: true },
  { name: "State Name", uid: "state_name", sortable: true },
  { name: "City", uid: "city" },
  { name: "Action", uid: "actions" },
];


const stateList = [
  {
    id: 1,
    coun_name: "India",
    state_name: "Tamil Nadu",
    city: "Yes",
  },
 
];

//Vehicle


const columnsVehicle = [
  
  { name: "Vehicle Reg No", uid: "veh_no", sortable: true },
  { name: "Vehicle Model", uid: "veh_mo", sortable: true },
  { name: "Vehicle Chassis No", uid: "veh_che" },
  { name: "Fuel type", uid: "fuel_ty", sortable: true },
  { name: "Fitness Certificate Valid", uid: "fit_valid", sortable: true },
  { name: "Permit Valid Period", uid: "permit" },
  { name: "Tax Valid", uid: "tax", sortable: true },
  { name: "Action", uid: "actions" },
];


const vehicleList = [
  {
    id: 1,
    veh_no: "AB12 CDE",
    veh_mo: "Toyota Corolla",
    veh_che: "JTDBU4EE9A1234567",
    fuel_ty: "Petrol",
    fit_valid: "2024-08-15",
    permit: "2025-01-01 to 2026-01-01",
    tax: "2025-12-31",
    
  },
 
];

//Vehicle


const columnsFuel = [
  
  { name: "Vehicle Reg No", uid: "veh_no", sortable: true },
  { name: "Fuel type", uid: "fuel_ty", sortable: true },
  { name: "Fuel Quantity", uid: "ful_qun" },
  { name: "Odometer Reading", uid: "oda", sortable: true },
  { name: "Fuel Expenses", uid: "fuel_exp", sortable: true },
  { name: "Action", uid: "actions" },
];


const fuelList = [
  {
    id: 1,
    veh_no: "AB12 CDE",
    fuel_ty: "Petrol",
    ful_qun: "50 liters",
    oda: "15000 km",
    fuel_exp: "$75",
  },

];

//Insurance


const columnsInsu = [
  
  { name: "Vehicle Reg No", uid: "veh_no", sortable: true },
  { name: "Vehicle Type", uid: "veh_ty", sortable: true },
  { name: "Coverage Type", uid: "co_ty" },
  { name: "Insurance Company", uid: "insu_com", sortable: true },
  { name: "Policy Purchase Date", uid: "poli_date", sortable: true },
  { name: "Policy Expiration Date", uid: "poli_exda", sortable: true },
  { name: "Coverage Limits", uid: "cov_lmt", sortable: true },
  { name: "Expire In", uid: "ex_in", sortable: true },
  { name: "Action", uid: "actions" },
];


const insuList = [
  {
    id: 1,
    veh_no: "AB123CD",
    veh_ty: "Sedan",
    co_ty: "Comprehensive",
    insu_com: "ABC Insurance",
    poli_date: "2023-01-15",
    poli_exda: "2024-01-15",
    cov_lmt: "$50,000",
    ex_in: "191 days",
    renewl: "Eligible",
  },

];

//Loan


const columnsLoan = [
  
  { name: "Vehicle Reg No", uid: "veh_no", sortable: true },
  { name: "Monthly Due", uid: "mon_due", sortable: true },
  { name: "Loan Term", uid: "loan_term" },
  { name: "Payment Day", uid: "pay_day", sortable: true },
  { name: "Loan Start Date", uid: "loan_start", sortable: true },
  { name: "Loan End Date", uid: "loan_end", sortable: true },
  { name: "Action", uid: "actions" },
];


const loanList = [
  {
    id: 1,
    veh_no: "QR345ST",
    mon_due: "$450",
    loan_term: "36 months",
    pay_day: "25th",
    loan_start: "2022-12-25",
    loan_end: "2025-12-25",
  },

];

//Loan


const columnsMain = [
  
  { name: "Vehicle Reg No", uid: "veh_no", sortable: true },
  { name: "Maintenance Type", uid: "main_type", sortable: true },
  { name: "Date of Service", uid: "date_service" },
  { name: "Mileage at Service", uid: "milleage", sortable: true },
  { name: "Service Description", uid: "service_desc", sortable: true },
  { name: "Maintenance Cost $", uid: "cost", sortable: true },
  { name: "Next Service Date", uid: "service", sortable: true },
  { name: "Action", uid: "actions" },
];


const mainList = [
  {
    id: 1,
   veh_no: "AB123CD",
   main_type: " Periodic Maintenance",
   date_service: "2023-06-15",
   milleage: "15,000 miles",
   service_desc: "Replaced oil filter and oil",
   cost: "$50",
   service: "2023-12-15",
    // "actions": "Schedule | View Details"
  },

];


//Accounts


const columnsAcc = [
  
  { name: "Customer Name", uid: "cus_name", sortable: true },
  { name: "Payment Terms", uid: "pay_term", sortable: true },
  { name: "0-7", uid: "7" },
  { name: "8-30", uid: "8", sortable: true },
  { name: "31-60", uid: "31", sortable: true },
  { name: "61-89", uid: "61", sortable: true },
  { name: ">90", uid: "90", sortable: true },
  { name: "Total Outstanding Amount", uid: "out_amt",  sortable: true},
];


const accList = [
  {
    id: 1,
    cus_name: "John Doe",
    pay_term: "Net 30",
    7: "$500",
    8: "$1,000",
    31: "$750",
    61: "$300",
    90: "$200",
    out_amt: "$2,750"
  },

];

export { columnsCustomer, customerList ,Rolecolumn, Rolelist, Departmentcolumn, Departmentlist, columnsContract, contractList, Invoicecolumn, Invoicelist,
  columnsEmployee, employeeList, columnsPayment, paymentList, columnsOrganization, organizationList, columnsTrip, tripList, columnsCodesetting, codesettingList,
  columnsEmpdocconfig, EmpdocconfigList, columnsEmpdoc, EmpdocList, columnsCountry, CountryList, columnsState, stateList, vehicleList, columnsVehicle,
  columnsFuel, fuelList, columnsInsu, insuList, columnsLoan, loanList, columnsMain, mainList, columnsAcc, accList
};