import React, { useState, useEffect } from 'react';
import { Input } from "@nextui-org/react";
import { MailIcon } from '../../components/baseComponent/MailIcon';
import { EyeFilledIcon } from "../../components/baseComponent/EyeFilledIcon";
import { EyeSlashFilledIcon } from "../../components/baseComponent/EyeSlashFilledIcon";
import { LockIcon } from '../../components/baseComponent/LockIcon';
import { Checkbox } from "@nextui-org/react";
import { Link, useNavigate } from 'react-router-dom';

const LoginData = [
  // {
  //   emailid: "Admin@gmail.com",
  //   password: "123",
  //   role: "chennai"
  // },
  {
    emailid: "manager@trims.com",
    password: "123",
    role: "manager"
  },
  {
    emailid: "employee@trims.com",
    password: "123",
    role: "employee"
  },
  {
    emailid: "proprietor@trims.com",
    password: "123",
    role: "proprietor"
  },
];

const Login = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const user = LoginData.find(
        (user) => user.emailid === email && user.password === password
      );

      if (user) {

        localStorage.setItem('loggedInUser', user.role);
        // if(user.role === "trainer"){
        //   navigate('/trainer/dashboard1')
        // }
        if(user.role ==="manager"){
          navigate('/manager/dashboard1')
        }
        if(user.role ==="employee"){
          navigate('/employee/dashboardEmp')
        }
        if(user.role ==="proprietor"){
          navigate('/proprietor/dashboard1')
        }

      } else {
        setError('Invalid username or password');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <section className='auth-section'>
      <div className="auth-section__wrapper lg:flex">
        <div className="left-side__wrapper min-h-dvh hidden lg:flex flex-col items-center justify-center lg:w-2/4 bg-[#36A2EB]">
        
          {/* <div className="content-wrapper flex flex-col gap-6" style={{background : "url('Tipper.png')" , width : "200%" , height : "100vh" , padding : "0px", display : "flex" , alignItems : "center" , justifyContent : "center" , backgroundPosition : "center"}}> */}
          <img src="Tipper.png" alt="Tipper.png" style={{width : "100%" , height : "100vh"}}/>
            {/* <h1 className='m-0 text-white font-bold text-4xl text-center uppercase'>Trims</h1> */}
            {/* <p className='m-0 text-white font-bold text-4xl text-center uppercase'>Accounting Software</p> */}
            {/* <p className='m-0 text-white font-bold text-4xl text-center uppercase'>For</p> */}
            {/* <p className='m-0 text-white font-bold text-4xl text-center uppercase'>Dynacom</p> */}
          {/* </div> */}
        </div>
        <div className="right-side__wrapper min-h-dvh py-4 px-6 flex flex-col justify-center lg:w-2/4">
          <div className="form-content__wrapper md:px-12 max-w-[600px] w-full mx-auto">
          <h3 className='m-0 mb-4 text-3xl font-bold text-black dark:text-white text-center'>KKB Engineering</h3>
            <div className="auth-form__title">
              <h3 className='m-0 mb-4 text-3xl font-bold text-black dark:text-white'>Sign In</h3>
              {/* <p className='m-0 text-sm text-black dark:text-white'>Welcome To DYNACOM!</p> */}
            </div>
            <form onSubmit={handleLogin} method="post">
              <div className="flex flex-col gap-6 mt-6">
                <Input
                  autoFocus
                  startContent={
                    <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Email"
                  placeholder="Enter your email"
                  variant="bordered"
                  labelPlacement='outside'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  startContent={
                    <LockIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0"/>
                  }
                  endContent={
                    <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                      {isVisible ? (
                        <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      ) : (
                        <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      )}
                    </button>
                  }
                  label="Password"
                  placeholder="Enter your password"
                  type={isVisible ? "text" : "password"}
                  variant="bordered"
                  labelPlacement='outside'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {error && <p className="text-red-500 text-sm">{error}</p>}
                <div className="flex justify-between items-center w-full">
                  <Checkbox defaultSelected className='py-0' radius="sm" size="md" color="primary">Remember me</Checkbox>
                  <Link to={"/forget-password"} className='text-[#36A2EB] text-md'>
                    Forgot Password?
                  </Link>
                </div>
                <button type="submit" className="py-3 px-3 bg-[#36A2EB] hover:bg-[#36A2EBd4] text-white text-xs font-semibold rounded-md shadow focus:outline-none">
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
