import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    TimeInput,
    Input,
    DatePicker,
    Textarea,
    Select,
    SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";

const EmployeeForm = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate("/manager/employee");
    };

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <h1 className="report-heading">Add Employee Details</h1>
            <div className="px-4">
                <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div className="form-row">

                                    <Input
                                        variant="bordered"
                                        label="First Name"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Last Name"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Mail ID"
                                        labelPlacement="outside"
                                    />

                                </div>
                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Father's Name"
                                        labelPlacement="outside"
                                    />
                                    <DatePicker
                                        variant="bordered"
                                        label="Date Of Birth"
                                        labelPlacement="outside"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Address"
                                        labelPlacement="outside"
                                    />
                                </div>
                                {/* </div> */}
                                <div className="form-row">

                                    <Input
                                        variant="bordered"
                                        label="Previous Year Experience"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Country"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="State"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                </div>
                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="City"
                                        labelPlacement="outside"
                                        type="email"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Zipcode"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Contact Number"
                                        labelPlacement="outside"
                                    />
                                </div>

                                <div className="form-row">

                                    <Input
                                        variant="bordered"
                                        label="Secondary Number"
                                        labelPlacement="outside"
                                        type="email"
                                    />

                                    <Select
                                        label="Department"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="Customer Service and Support Department" value="Customer Service and Support Department">
                                            Customer Service and Support Department
                                        </SelectItem>
                                        <SelectItem key="Finance and Budgeting Department" value="Finance and Budgeting Department">
                                            Finance and Budgeting Department
                                        </SelectItem>
                                    </Select>

                                    <DatePicker
                                        variant="bordered"
                                        label="Hire Date"
                                        labelPlacement="outside"
                                        type="email"
                                    />

                                </div>
                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Manager"
                                        labelPlacement="outside"
                                        type="email"
                                    />
                                    <Select
                                        label="Role"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key=" Booking Agent" value=" Booking Agent">
                                            Booking Agent
                                        </SelectItem>
                                        <SelectItem key="Finance Manager" value="Finance Manager">
                                            Finance Manager
                                        </SelectItem>
                                    </Select>

                                    <Select
                                        label="Is User"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="yes" value="yes">
                                            Yes
                                        </SelectItem>
                                        <SelectItem key="no" value="no">
                                            No
                                        </SelectItem>
                                    </Select>

                                </div>

                                <div className="form-row">

                                    <Select
                                        label="Probation Period"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="1 Month" value="1 Month">
                                            1 Month
                                        </SelectItem>
                                        <SelectItem key="2 Month" value="2 Month">
                                            2 Month
                                        </SelectItem>
                                        <SelectItem key="3 Month" value="3 Month">
                                            3 Month
                                        </SelectItem>
                                        <SelectItem key="4 Month" value="4 Month">
                                            4 Month
                                        </SelectItem>

                                        <SelectItem key="5 Month" value="5 Month">
                                            5 Month
                                        </SelectItem>
                                        <SelectItem key="6 Month" value="6 Month">
                                            6 Month
                                        </SelectItem>

                                        <SelectItem key="7 Month" value="7 Month">
                                            7 Month
                                        </SelectItem>
                                        <SelectItem key="8 Month" value="8 Month">
                                            8 Month
                                        </SelectItem>

                                        <SelectItem key="9 Month" value="9 Month">
                                            9 Month
                                        </SelectItem>
                                        <SelectItem key="10 Month" value="10 Month">
                                            10 Month
                                        </SelectItem>

                                        <SelectItem key="11 Month" value="11 Month">
                                            11 Month
                                        </SelectItem>
                                        <SelectItem key="12 Month" value="12 Month">
                                            12 Month
                                        </SelectItem>

                                    </Select>

                                </div>

                                <div className="file-input">
                                    <input
                                        type="file"
                                        id="attachment"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="attachment" className="file-label">
                                        <Input
                                            variant="bordered"
                                            label="Profile Picture"
                                            labelPlacement="outside"
                                            readOnly
                                            value={selectedFile ? selectedFile.name : ""}
                                            onClick={() => document.getElementById('attachment').click()}
                                        />
                                    </label>
                                </div>


                                <div className="flex justify-center items-center gap-4 mt-4" >
                                    <Button className="incidentSubmitBtn" type="submit" color="primary">
                                        Submit
                                    </Button>
                                    <Button color="danger" variant="flat" onClick={() => navigate('/manager/employee')}>
                                        Cancel
                                    </Button>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeForm;
