import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    TimeInput,
    Input,
    DatePicker,
    Textarea,
    Select,
    SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";

const InvoiceForm = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const tableHeaderStyle = {
        border: "1px solid black",
        padding: "8px",
        textAlign: "left",
        backgroundColor: "#f0f0f0",
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        navigate("/manager/invoiceprint");
    };


    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <h1 className="report-heading">Add Role Details</h1>
            <div className="px-4">
                <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div className="form-row form-row2">

                                    <DatePicker
                                        variant="bordered"
                                        label="Invoice Date"
                                        labelPlacement="outside"
                                    />

                                    <Select
                                        label="Customer Name"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="John Doe" value="John Doe">
                                            John Doe
                                        </SelectItem>
                                        <SelectItem key="Emily Davis" value="Emily Davis">
                                            Emily Davis
                                        </SelectItem>
                                    </Select>


                                    <Select
                                        label="Payment Terms"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="John Doe" value="John Doe">
                                            NET 30
                                        </SelectItem>
                                        <SelectItem key="Emily Davis" value="Emily Davis">
                                            NET 10
                                        </SelectItem>
                                    </Select>

                                </div>

                                {/* </div> */}
                                <div className="form-row form-row2">
                                    <DatePicker
                                        variant="bordered"
                                        label="Due Date"
                                        labelPlacement="outside"
                                        type="tel"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Tax %"
                                        labelPlacement="outside"
                                        type="tel"
                                    />

                                </div>

                                <div className="flex justify-center items-center gap-1 mt-5">
                                    <Button color="primary">
                                        Show
                                    </Button>
                                </div>

                                <div className="flex flex-col gap-1 my-3">
                                    Day Trip
                                </div>

                                <table id="row-select">
                                    <thead>
                                        <tr>
                                            <th style={tableHeaderStyle}>Trip Date</th>
                                            <th style={tableHeaderStyle}>Vehicle Reg No</th>
                                            <th style={tableHeaderStyle}>Ticket No</th>
                                            <th style={tableHeaderStyle}>Material</th>
                                            <th style={tableHeaderStyle}>Number of Hrs</th>
                                            <th style={tableHeaderStyle}>OT/Hrs</th>
                                            <th style={tableHeaderStyle}>Day Amount</th>
                                            <th style={tableHeaderStyle}>OT Amount</th>
                                            <th style={tableHeaderStyle}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody id="day_trip_tbody">
                                        {/* Data will be populated here */}
                                    </tbody>
                                </table>
                                <div>
                                    <p>Daytrip Amount: {/* Calculate and display day trip amount here */}</p>
                                    <input type="hidden" className="form-control input-default" id="daytrip_amount" />
                                </div>

                                <div className="flex flex-col gap-1 mt-10">
                                    Load Trip
                                </div>

                                <table id="row-select">
                                    <thead>
                                        <tr>
                                            <th style={tableHeaderStyle}>Trip Date</th>
                                            <th style={tableHeaderStyle}>Vehicle Reg No</th>
                                            <th style={tableHeaderStyle}>Ticket No</th>
                                            <th style={tableHeaderStyle}>Material</th>
                                            <th style={tableHeaderStyle}>Load</th>
                                            <th style={tableHeaderStyle}>Load Amount</th>
                                            <th style={tableHeaderStyle}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody id="day_trip_tbody">
                                        {/* Data will be populated here */}
                                    </tbody>
                                </table>
                                <div>
                                    <p>Loadtrip Amount: {/* Calculate and display day trip amount here */}</p>
                                    <input type="hidden" className="form-control input-default" id="daytrip_amount" />
                                </div>


                                <div className="flex justify-center items-center gap-4 mt-4" >
                                <Button color="primary" onClick={handleSubmit}>
                                    Submit & Preview
                                </Button>
                                <Button color="danger" variant="flat" onClick={() => navigate('/manager/invoice')}>
                    Cancel
                  </Button>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceForm;
