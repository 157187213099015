import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TimeInput,
  Input,
  DatePicker,
  Textarea,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";

const ContractForm = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/manager/contract");
  };

  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <h1 className="report-heading">Add Contract Details</h1>
      <div className="px-4">
        <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div>
              <div className="form-row">
                                    <Select
                                        label="Customer Name"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="John Doe" value="John Doe">
                                            John Doe
                                        </SelectItem>
                                        <SelectItem key="Emily Davis" value="Emily Davis">
                                            Emily Davis
                                        </SelectItem>
                                    </Select>

                                    <Input
                                        variant="bordered"
                                        label="Contact Number"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="GST No"
                                        labelPlacement="outside"
                                    />

                                </div>
                                <div className="flex flex-col gap-1 my-3">
                                    Organization Details
                                </div>

                                {/* </div> */}
                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Organization Name"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Contact Number"
                                        labelPlacement="outside"
                                        type="tel"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Reg No"
                                        labelPlacement="outside"
                                        type="email"
                                    />
                                </div>


                                <div className="flex flex-col  gap-1 my-3">
                                    Contract Details
                                </div>

                                <div className="form-row">
                                    <DatePicker
                                        variant="bordered"
                                        label="Contract Date"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <DatePicker
                                        variant="bordered"
                                        label="Start Date"
                                        labelPlacement="outside"
                                        type="tel"
                                    />

                                    <DatePicker
                                        variant="bordered"
                                        label="End Date"
                                        labelPlacement="outside"
                                        type="email"
                                    />
                                </div>
                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Duration"
                                        labelPlacement="outside"
                                        type="email"
                                    />

                                </div>

                                <div className="flex flex-col gap-1 my-3">
                                    Amount Details
                                </div>

                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Amount Per Trip"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Amount Per Load"
                                        labelPlacement="outside"
                                        type="tel"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="OT Amount"
                                        labelPlacement="outside"
                                        type="email"
                                    />
                                </div>

                                <div className="form-row">
                                
                                    <Select
                                        label="Billing Cycle"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="Monthly" value="Monthly">
                                            Monthly
                                        </SelectItem>
                                        <SelectItem key="Weekly" value="Weekly">
                                        Weekly
                                        </SelectItem>
                                        <SelectItem key="Quaterly" value="Quaterly">
                                        Quaterly
                                        </SelectItem>
                                        <SelectItem key="Yearly" value="Yearly">
                                        Yearly
                                        </SelectItem>

                                    </Select>
                                    <Input
                                        variant="bordered"
                                        label="Billing Mode"
                                        labelPlacement="outside"
                                    />
                                </div>

                                <div className="flex flex-col gap-1 my-3">
                                    Terms and Conditions
                                </div>

                                <div className="form-row">
                                    <Select
                                        label="Payment Terms"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="7" value="7">
                                            NET 7
                                        </SelectItem>
                                        <SelectItem key="10" value="10">
                                            NET 10
                                        </SelectItem>

                                        <SelectItem key="15" value="15">
                                            NET 15
                                        </SelectItem>
                                        <SelectItem key="30" value="30">
                                            NET 30
                                        </SelectItem>
                                    </Select>

                                </div>

                                <div className="form-row form-row2">

                                    <Textarea
                                        variant="bordered"
                                        //   labelPlacement="outside"
                                        label="Terms & Conditions"
                                        placeholder="Enter your Terms & Conditions"
                                        className="max-w-full"
                                    />

                                </div>

                <div className="flex justify-center items-center gap-4 mt-4" >
                  <Button className="incidentSubmitBtn" type="submit" color="primary">
                    Submit
                  </Button>
                  <Button color="danger" variant="flat" onClick={() => navigate('/manager/contract')}>
                    Cancel
                  </Button>
                </div>

              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractForm;
