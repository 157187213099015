import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    TimeInput,
    Input,
    DatePicker,
    Textarea,
    Select,
    SelectItem,
    ModalBody,
   
} from "@nextui-org/react";
import "./ModelForm.css";

const PaymentForm = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate("/manager/payment");
    };


    const tableHeaderStyle = {
        border: "1px solid black",
        padding: "8px",
        textAlign: "left",
        backgroundColor: "#f0f0f0",
    };


    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <h1 className="report-heading">Payment Details</h1>
            <div className="px-4">
                <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div className="form-row">

                                    <Select
                                        label="Customer Name"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="John Doe" value="John Doe">
                                            John Doe
                                        </SelectItem>
                                        <SelectItem key="Emily Davis" value="Emily Davis">
                                            Emily Davis
                                        </SelectItem>
                                    </Select>

                                    <DatePicker
                                        variant="bordered"
                                        label="Receipt Date"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Outstanding Amount"
                                        labelPlacement="outside"
                                        type="tel"
                                    />

                                </div>

                                {/* </div> */}
                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Amount"
                                        labelPlacement="outside"
                                        type="tel"
                                    />

                                    <Select
                                        label="Payment Mode"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="Cash" value="Cash">
                                            Cash
                                        </SelectItem>
                                        <SelectItem key="Bank Transfer" value="Bank Transfer">
                                            Bank Transfer
                                        </SelectItem>
                                        <SelectItem key="Cheque" value="Cheque">
                                            Cheque
                                        </SelectItem>
                                    </Select>

                                </div>




                                <div className="flex flex-col gap-1 mt-4">
                                    Payment Allocation
                                </div>

                                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                                    <table id="row-select">
                                        <thead>
                                            <tr>
                                                <th style={tableHeaderStyle}>Invoice Number</th>
                                                <th style={tableHeaderStyle}>Invoice Date</th>
                                                <th style={tableHeaderStyle}>Due Date</th>
                                                <th style={tableHeaderStyle}>Invoice Amount</th>
                                                <th style={tableHeaderStyle}>Remaining Amount</th>
                                                <th style={tableHeaderStyle}>Allocation Amount</th>
                                                <th style={tableHeaderStyle}>Outstanding Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody id="day_trip_tbody">
                                            {/* Data will be populated here */}
                                        </tbody>
                                    </table>

                                </div>


                                <div className="flex justify-center items-center gap-4 mt-4" >
                                    <Button className="incidentSubmitBtn" type="submit" color="primary">
                                        Submit
                                    </Button>
                                    <Button color="danger" variant="flat" onClick={() => navigate('/manager/payment')}>
                                        Cancel
                                    </Button>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div >
        </div >
    );
};

export default PaymentForm;
