import { Route, Routes } from "react-router-dom";
import Layout from './components/groupComponent/layout';
import Providers from "./components/contentApi/Providers";
import Login from "./page/auth/Login";


import Dashboard1 from "./page/Dashboard1";

import Department from "./page/Department"
import Role from "./page/Role";
import Customer from "./page/Customer";
import Contract from "./page/Contract";
import Invoice from "./page/Invoice";
import Invoiceprint from "./page/Invoiceprint";
import InvoiceForm from "./components/baseComponent/InvoiceForm";
import Employee from "./page/Employee";
import Payment from "./page/Payment";
import Organization from "./page/Organization";
import Trip from "./page/Trip";
import Codesetting from "./page/Codesetting";
import Emp_doc from "./page/Emp_doc";
import Employee_Document from "./page/Employee_Document";
import Country from "./page/Country";
import State from "./page/State";
// import Roleaccess from "./page/Roleaccess";
import Vehicle from "./page/Vehicle";
import Fuel from "./page/Fuel";
import Insurance from "./page/Insurance";
import Loan from "./page/Loan";
import Vehiclemaintenance from "./page/Vehiclemaintenance";
import Accounts from "./page/Accounts";
import Roleaccess from "./page/Roleaccess";
import RoleForm from "./components/baseComponent/RoleForm";
import CustomerForm from "./components/baseComponent/CustomerForm";
import ContractForm from "./components/baseComponent/ContractForm";
import TripForm from "./components/baseComponent/TripForm";
import EmployeeForm from "./components/baseComponent/EmployeeForm";
import EmployeeDocumentForm from "./components/baseComponent/Employee_DocumentForm";
import CodesettingForm from "./components/baseComponent/CodesettingForm";
import EmpdocForm from "./components/baseComponent/Emp_doc_Form";
import CountryForm from "./components/baseComponent/CountryForm";
import StateForm from "./components/baseComponent/StateForm";
import VehicleForm from "./components/baseComponent/VehicleForm";
import FuelForm from "./components/baseComponent/FuelForm";
import InsuranceForm from "./components/baseComponent/InsuranceForm";
import LoanForm from "./components/baseComponent/LoanForm";
import VehiclemaintainanceForm from "./components/baseComponent/VehiclemaintenanceForm";
import PaymentForm from "./components/baseComponent/PaymentForm";
import Dashboardemployee from "./page/Dashboard2";




function App() {

  return (
    <>
      <Providers>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/manager" element={<Layout />}>
            <Route path="dashboard1" element={<Dashboard1 />} />

            <Route path="roles" element={<Role />} />
            <Route path="roleform" element={<RoleForm />} />

            <Route path="customer" element={<Customer />} />
            <Route path="customerform" element={<CustomerForm />} />

            <Route path="contract" element={<Contract />} />
            <Route path="contractform" element={<ContractForm />} />

            <Route path="trip" element={<Trip />} />
            <Route path="tripform" element={<TripForm />} />

            <Route path="employee" element={<Employee />} />
            <Route path="employeeform" element={<EmployeeForm />} />

            <Route path="employee_document" element={<Employee_Document />} />
            <Route path="employeedocumentform" element={<EmployeeDocumentForm />} />

            <Route path="code" element={<Codesetting />} />
            <Route path="codeform" element={<CodesettingForm />} />

            <Route path="emp_doc" element={<Emp_doc />} />
            <Route path="emp_docform" element={<EmpdocForm />} />

            <Route path="roleaccess" element={<Roleaccess />} />

            <Route path="country" element={<Country />} />
            <Route path="countryform" element={<CountryForm />} />

            <Route path="state" element={<State />} />
            <Route path="stateform" element={<StateForm />} />

            <Route path="vehicle" element={<Vehicle />} />
            <Route path="vehicleform" element={<VehicleForm />} />

            <Route path="fuel" element={<Fuel />} />
            <Route path="fuelform" element={<FuelForm />} />

            <Route path="insu" element={<Insurance />} />
            <Route path="insuform" element={<InsuranceForm />} />

            <Route path="loan" element={<Loan />} />
            <Route path="loanform" element={<LoanForm />} />

            <Route path="main" element={<Vehiclemaintenance />} />
            <Route path="mainform" element={<VehiclemaintainanceForm />} />

            <Route path="payment" element={<Payment />} />
            <Route path="paymentform" element={<PaymentForm />} />




            <Route path="Department" element={<Department />} />

            <Route path="invoice" element={<Invoice />} />
            <Route path="invoiceprint" element={<Invoiceprint />} />
            <Route path="invoiceform" element={<InvoiceForm />} />
            <Route path="org" element={<Organization />} />
            {/* <Route path="roleaccess" element={<Roleaccess/>} /> */}
            <Route path="acc" element={<Accounts />} />
          </Route>

          {/* ---------------EMPLOYEEEE--------------------------------*/}


          <Route path="/employee" element={<Layout />}>
            <Route path="dashboardEmp" element={<Dashboardemployee />} />

            <Route path="trip" element={<Trip />} />
            <Route path="tripform" element={<TripForm />} />

            <Route path="employee" element={<Employee />} />
            <Route path="employeeform" element={<EmployeeForm />} />

            <Route path="employee_document" element={<Employee_Document />} />
            <Route path="employeedocumentform" element={<EmployeeDocumentForm />} />

            <Route path="vehicle" element={<Vehicle />} />
            <Route path="vehicleform" element={<VehicleForm />} />

            <Route path="fuel" element={<Fuel />} />
            <Route path="fuelform" element={<FuelForm />} />

            <Route path="insu" element={<Insurance />} />
            <Route path="insuform" element={<InsuranceForm />} />

            <Route path="loan" element={<Loan />} />
            <Route path="loanform" element={<LoanForm />} />

            <Route path="main" element={<Vehiclemaintenance />} />
            <Route path="mainform" element={<VehiclemaintainanceForm />} />


          </Route>

          {/* -------------------------------------proprietor-------------------------------------------------- */}
          <Route path="/proprietor" element={<Layout />}>
            <Route path="dashboard1" element={<Dashboard1 />} />

            <Route path="trip" element={<Trip />} />
            <Route path="tripform" element={<TripForm />} />

            <Route path="employee" element={<Employee />} />
            <Route path="employeeform" element={<EmployeeForm />} />

            <Route path="employee_document" element={<Employee_Document />} />
            <Route path="employeedocumentform" element={<EmployeeDocumentForm />} />

            <Route path="vehicle" element={<Vehicle />} />
            <Route path="vehicleform" element={<VehicleForm />} />

            <Route path="fuel" element={<Fuel />} />
            <Route path="fuelform" element={<FuelForm />} />

            <Route path="insu" element={<Insurance />} />
            <Route path="insuform" element={<InsuranceForm />} />

            <Route path="loan" element={<Loan />} />
            <Route path="loanform" element={<LoanForm />} />

            <Route path="main" element={<Vehiclemaintenance />} />
            <Route path="mainform" element={<VehiclemaintainanceForm />} />

            <Route path="customer" element={<Customer />} />
            <Route path="customerform" element={<CustomerForm />} />

            <Route path="contract" element={<Contract />} />
            <Route path="contractform" element={<ContractForm />} />




            <Route path="payment" element={<Payment />} />
            <Route path="paymentform" element={<PaymentForm />} />

            <Route path="invoice" element={<Invoice />} />
            <Route path="invoiceprint" element={<Invoiceprint />} />
            <Route path="invoiceform" element={<InvoiceForm />} />
            <Route path="acc" element={<Accounts />} />


          </Route>


          {/* --------------------------------------------------------------------------------------- */}

        </Routes>

      </Providers>
    </>
  );
}

export default App;
