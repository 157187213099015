import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    TimeInput,
    Input,
    DatePicker,
    Textarea,
    Select,
    SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";

const LoanForm = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate("/manager/loan");
    };

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <h1 className="report-heading">Loan Details</h1>
            <div className="px-4">
                <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div className="form-row">

                                    <Input
                                        variant="bordered"
                                        label="Vehicle Registration number"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Principal Loan Amount"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Outstanding Loan Amount"
                                        labelPlacement="outside"
                                    />



                                </div>
                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Amount of Paid"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Monthly Due"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Rate of Interest"
                                        labelPlacement="outside"
                                    />
                                </div>
                                {/* </div> */}
                                <div className="form-row">

                                    <Input
                                        variant="bordered"
                                        label="Loan Term"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Payment Day"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <DatePicker
                                        variant="bordered"
                                        label="Loan Start Date"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                </div>
                                <div className="form-row">
                                    <DatePicker
                                        variant="bordered"
                                        label="Loan End Date"
                                        labelPlacement="outside"
                                        type="email"
                                    />

                                </div>

                                <div className="flex justify-center items-center gap-4 mt-4" >
                                    <Button className="incidentSubmitBtn" type="submit" color="primary">
                                        Submit
                                    </Button>
                                    <Button color="danger" variant="flat" onClick={() => navigate('/manager/loan')}>
                                        Cancel
                                    </Button>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoanForm;
