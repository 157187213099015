import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    TimeInput,
    Input,
    DatePicker,
    Textarea,
    Select,
    SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";

import { getNames } from "country-list";





const VehicleForm = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);

    
    const countries = getNames();

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate("/manager/vehicle");
    };

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <h1 className="report-heading">Vehicle  Details</h1>
            <div className="px-4">
                <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div className="form-row">

                                    <Input
                                        variant="bordered"
                                        label="Vehicle Reg no"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Vehicle Model"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Odometer Reading"
                                        labelPlacement="outside"
                                    />

                                </div>
                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Vehicle Chassis No"
                                        labelPlacement="outside"
                                    />
                                    <Select
                                        label="Fuel type"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="Petrol(92-Octane)" value="Petrol(92-Octane)">
                                            Petrol(92-Octane)
                                        </SelectItem>

                                        <SelectItem key="Petrol(92-Octane)" value="Petrol(92-Octane)">
                                            Petrol(95-Octane)
                                        </SelectItem>

                                        <SelectItem key="Petrol(92-Octane)" value="Petrol(92-Octane)">
                                            Petrol(98-Octane)
                                        </SelectItem>

                                        <SelectItem key="Petrol(92-Octane)" value="Petrol(92-Octane)">
                                            Diesel
                                        </SelectItem>

                                        <SelectItem key="Petrol(92-Octane)" value="Petrol(92-Octane)">
                                            LPG gas
                                        </SelectItem>

                                    </Select>

                                    <Select
                                        label="Owner Type"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="own vehicle" value="own vehicle">
                                            own vehicle
                                        </SelectItem>

                                        <SelectItem key="Rental vehicle" value="Rental vehicle">
                                            Rental vehicle
                                        </SelectItem>

                                        <SelectItem key="Leasing" value="Leasing">
                                            Leasing
                                        </SelectItem>

                                    </Select>
                                </div>
                                {/* </div> */}
                                <div className="form-row">

                                    <Input
                                        variant="bordered"
                                        label="Mileage"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <DatePicker
                                        variant="bordered"
                                        label="Fitness Certificate Valid"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <DatePicker
                                        variant="bordered"
                                        label="Permit Valid Period"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                </div>
                                <div className="form-row">
                                    <DatePicker
                                        variant="bordered"
                                        label="Tax Valid"
                                        labelPlacement="outside"
                                        type="email"
                                    />
                                    <Select
                                        label="Vehicle type"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="Dump Truck" value="Dump Truck">
                                            Dump Truck
                                        </SelectItem>

                                        <SelectItem key="Crane Truck" value="Crane Truck">
                                            Crane Truck
                                        </SelectItem>

                                        <SelectItem key="Refrigerated Truck" value="Refrigerated Truck">
                                            Refrigerated Truck
                                        </SelectItem>

                                        <SelectItem key="Tanker Truck" value="Tanker Truck">
                                            Tanker Truck
                                        </SelectItem>

                                        <SelectItem key="Garbage Truck" value="Garbage Truck">
                                            Garbage Truck
                                        </SelectItem>

                                    </Select>

                                    <Input
                                        variant="bordered"
                                        label="Manufacturer"
                                        labelPlacement="outside"
                                    />
                                </div>

                                <div className="form-row">

                                    <Input
                                        variant="bordered"
                                        label="Year of Manufacture"
                                        labelPlacement="outside"
                                        type="email"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Vehicle Weight"
                                        labelPlacement="outside"
                                        type="email"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Maximum Load"
                                        labelPlacement="outside"
                                        type="email"
                                    />

                                </div>
                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Additional Equipment"
                                        labelPlacement="outside"
                                        type="email"
                                    />

                                </div>

                                <div className="flex justify-center items-center gap-4 mt-4" >
                                    <Button className="incidentSubmitBtn" type="submit" color="primary">
                                        Submit
                                    </Button>
                                    <Button color="danger" variant="flat" onClick={() => navigate('/manager/vehicle')}>
                                        Cancel
                                    </Button>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default VehicleForm;
