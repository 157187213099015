import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TimeInput,
  Input,
  DatePicker,
  Textarea,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";

const EmployeeDocumentForm = () => {
  const navigate = useNavigate();
    
  const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };


  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/manager/employee_document");
  };

  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <h1 className="report-heading">Add Employee Document Details</h1>
      <div className="px-4">
        <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div>
              <div className="form-row">

<Input
    variant="bordered"
    label="Employee Name"
    labelPlacement="outside"
/>

<Input
    variant="bordered"
    label="Employee ID"
    labelPlacement="outside"
/>

<Input
    variant="bordered"
    label="Department"
    labelPlacement="outside"
/>

</div>

<div className="form-row">
<Input
    variant="bordered"
    label="Role"
    labelPlacement="outside"
/>
<Input
    variant="bordered"
    label="Nationality"
    labelPlacement="outside"
/>
<Input
    variant="bordered"
    label="Document Name"
    labelPlacement="outside"
/>
</div>


<div className="file-input">
<input
    type="file"
    id="attachment"
    style={{ display: "none" }}
    onChange={handleFileChange}
/>
<label htmlFor="attachment" className="file-label">
    <Input
        variant="bordered"
        label="Attachment"
        labelPlacement="outside"
        readOnly
        value={selectedFile ? selectedFile.name : ""}
        onClick={() => document.getElementById('attachment').click()}
    />
</label>
</div>


                <div className="flex justify-center items-center gap-4 mt-4" >
                  <Button className="incidentSubmitBtn" type="submit" color="primary">
                    Submit
                  </Button>
                  <Button color="danger" variant="flat" onClick={() => navigate('/manager/employee_document')}>
                    Cancel
                  </Button>
                </div>

              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDocumentForm;
