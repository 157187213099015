import React from "react";

const Invoiceprint = ({
    email,
    mobileNo,
    customerDisplayName,
    billingAddress1,
    billingAddress2,
    billingLandmark,
    billingCity,
    billingState,
    billingCountry,
    billingPincode,
    invoiceDate,
    gstNo,
    invoiceNo,
    paymentTerms,
    daytripAmount,
    loadtripAmount,
    totalAmount,
    taxPercent,
    taxAmount,
    invoiceAmount,
    invoiceAmountText,
}) => {
    const tableHeaderStyle = {
        border: "1px solid black",
        padding: "8px",
        backgroundColor: "#f0f0f0",
        textAlign: "left",
    };

    const tableDataStyle = {
        border: "1px solid black",
        padding: "8px",
        textAlign: "left",
    };

    const tableTitleStyle = {
        backgroundColor: "#f0f0f0",
        textAlign: "left",
    };

    const containerStyle = {
        width: "100%",
        maxWidth: "210mm", // A4 width
        margin: "0 auto",
        padding: "20px",
        border: "1px solid black",
        boxSizing: "border-box",
    };

    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
            <div id="print-container" style={containerStyle}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={`${process.env.PUBLIC_URL}/images/KKB_logo.png`} alt="logo" width="200" />
                        <div style={{ marginLeft: "20px" }}>
                            <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
                                <li>Email: {email}</li>
                                <li>Contact: {mobileNo}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <h6>{customerDisplayName}</h6>
                    <h6>{billingAddress1}, {billingAddress2}</h6>
                    <h6>{billingLandmark}</h6>
                    <h6>{billingCity}</h6>
                    <h6>{billingState}</h6>
                    <h6>{billingCountry}</h6>
                    <h6>{billingPincode}</h6>
                </div>
                <div style={{ marginTop: "20px", textAlign: "right" }}>
                    <div>
                        <h6 style={{ display: "inline-block", marginRight: "10px" }}>Date:</h6>
                        <h6 style={{ display: "inline-block" }}>{invoiceDate}</h6>
                    </div>
                    <div>
                        <h6 style={{ display: "inline-block", marginRight: "10px" }}>GST Reg No:</h6>
                        <h6 style={{ display: "inline-block" }}>{gstNo}</h6>
                    </div>
                    <div>
                        <h6 style={{ display: "inline-block", marginRight: "10px" }}>Invoice No:</h6>
                        <h6 style={{ display: "inline-block" }}>{invoiceNo}</h6>
                    </div>
                    <div>
                        <h6 style={{ display: "inline-block", marginRight: "10px" }}>Term:</h6>
                        <h6 style={{ display: "inline-block" }}>{paymentTerms}</h6>
                    </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                            <tr style={tableTitleStyle}>
                                <th style={tableHeaderStyle}>DESCRIPTION</th>
                                <th style={tableHeaderStyle}>AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={tableDataStyle}>TRANSPORT CLAIM</td>
                                <td style={tableDataStyle}></td>
                            </tr>
                            <tr>
                                <td style={tableDataStyle}>TMA Vehicle Rental</td>
                                <td style={tableDataStyle}>${daytripAmount}</td>
                            </tr>
                            <tr>
                                <td style={tableDataStyle}>Tipper Vehicle Rental</td>
                                <td style={tableDataStyle}>${loadtripAmount}</td>
                            </tr>
                            <tr>
                                <td style={tableDataStyle}>Total</td>
                                <td style={tableDataStyle}>${totalAmount}</td>
                            </tr>
                            <tr>
                                <td style={tableDataStyle}>Tax: {taxPercent}%</td>
                                <td style={tableDataStyle}>${taxAmount}</td>
                            </tr>
                            <tr style={tableTitleStyle}>
                                <td style={tableHeaderStyle}>Grand Total</td>
                                <td style={tableHeaderStyle}>${invoiceAmount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                    <p>Singapore Dollar: {invoiceAmountText}</p>
                </div>
            </div>
            <div style={{ marginTop: "20px", display: "flex", justifyContent: "flex-end" }}>
                <button
                    style={{
                        backgroundColor: "#00a2ff",
                        color: "white",
                        border: "none",
                        padding: "10px 20px",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        const printContents = document.getElementById('print-container').innerHTML;
                        const originalContents = document.body.innerHTML;
                        document.body.innerHTML = printContents;
                        window.print();
                        document.body.innerHTML = originalContents;
                        window.location.reload(); // Reload the page to restore original content
                    }}
                >
                    PRINT
                </button>
            </div>
        </div>
    );
};

export default Invoiceprint;
