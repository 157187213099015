import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  // TimeInput,
  Input,
  DatePicker,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css"

export default function DepartmentForm({ isOpen, onOpenChange, backdrop = "blur" }) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop={backdrop}
        placement="center"
        style={{ maxWidth: "700px", width: "100%" }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Department
              </ModalHeader>
              <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
            
              <div className="form-row">
                <Input
                  variant="bordered"
                  label="Department Code"
                  labelPlacement="outside"
                  type="number"
                />
                <Input
                  variant="bordered"
                  label="Department Name"
                  labelPlacement="outside"
                />
                </div>
            
              </ModalBody>
              <ModalFooter  className="flex justify-center items-center gap-4" >
                <Button color="danger" variant="flat" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="primary" onPress={onClose}>
                  Submit
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
