import React, { useState } from 'react';
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Chip,
    User,
    Pagination,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Textarea,
    Select,
    SelectItem,
} from "@nextui-org/react";
import { PlusIcon } from "./PlusIcon";
import { VerticalDotsIcon } from "./VerticalDotsIcon";
import { SearchIcon } from "./SearchIcon";
import { ChevronDownIcon } from "./ChevronDownIcon";
import { columnsOrganization, organizationList } from "../../data/data";
import { capitalize } from "../../utils/common";

const statusColorMap = {
    active: "success",
    paused: "danger",
    vacation: "warning",
};

const statusTimeMap = {
    earlyin: "success",
    earlyout: "danger",
};


const INITIAL_VISIBLE_COLUMNS = ["reg_no", "fis_year", "location", "address", "email", "mob_no", "actions"];

export default function OrganizationTable() {
    const { isOpen: isLevelModalOpen, onOpen: onLevelModalOpen, onOpenChange: onLevelModalOpenChange } = useDisclosure();
    const { isOpen: isApproverModalOpen, onOpen: onApproverModalOpen, onOpenChange: onApproverModalOpenChange } = useDisclosure();
    const [selectedFile, setSelectedFile] = useState(null);
    const [modalPlacement, setModalPlacement] = React.useState("auto");
    const [rows, setRows] = useState([{ id: 1, level: 1 }]);
    const [nextId, setNextId] = useState(2); // Starting id for new rows

    const [filterValue, setFilterValue] = React.useState("");
    const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = React.useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [statusFilter, setStatusFilter] = React.useState("all");
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [sortDescriptor, setSortDescriptor] = React.useState({
        column: "age",
        direction: "ascending",
    });
    const [page, setPage] = React.useState(1);

    const handleAddRow = () => {
        const newRow = { id: nextId, level: rows.length + 1 };
        setRows([...rows, newRow]);
        setNextId(nextId + 1);
    };

    const handleRemoveRow = (id) => {
        const updatedRows = rows.filter(row => row.id !== id);
        setRows(updatedRows);
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };


    const handleInputChange = (id, field, value) => {
        const updatedRows = rows.map(row => {
            if (row.id === id) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setRows(updatedRows);
    };

    const handleUpdate = () => {
        // Implement update logic here
        // This function will be called when the Update button is clicked
    };

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return columnsOrganization;

        return columnsOrganization.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    const filteredItems = React.useMemo(() => {
        let filteredUsers = [...organizationList];

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.date.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }
        return filteredUsers;
    }, [hasSearchFilter, filterValue]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    const renderCell = React.useCallback((user, columnKey) => {
        const cellValue = user[columnKey];

        switch (columnKey) {
            case "name":
                return (
                    <User
                        avatarProps={{ radius: "lg", src: user.avatar }}
                        description={user.email}
                        name={cellValue}
                    >
                        {user.email}
                    </User>
                );
            case "sma_id":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.sma_id}</p>
                );

            case "surname":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.surname}</p>
                );
            case "given_name":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.given_name}</p>
                );
            case "rank":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.rank}</p>
                );
            case "vessel":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.vessel}</p>
                );
            case "qty":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.qty}</p>
                );
            case "sign_on_date":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.sign_on_date}</p>
                );
            case "amount":
                return (
                    <p className="text-bold text-small whitespace-nowrap capitalize m-0">{user?.amount}</p>
                );
            case "actions":
                return (
                    <div className="relative flex justify-center items-center gap-2">
                        <Dropdown className="dark:bg-neutral-800">
                            <DropdownTrigger>
                                <Button isIconOnly size="sm" variant="light">
                                    <VerticalDotsIcon className="text-default-300" />
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu>
                                <DropdownItem onPress={onLevelModalOpen}>Edit</DropdownItem>
                                {/* <DropdownItem onPress={onApproverModalOpen}>Workflow Approver</DropdownItem> */}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                );
            default:
                return cellValue;
        }
    }, []);

    const onRowsPerPageChange = React.useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("")
        setPage(1)
    }, [])

    const topContent = React.useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                                    Columns
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {columnsOrganization.map((column) => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {capitalize(column.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-default-400 text-small">Total {organizationList.length} users</span>
                    <label className="flex items-center text-default-400 text-small">
                        Rows per page:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        onClear,
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        onSearchChange,
    ]);

    const bottomContent = React.useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <span className="w-[30%] text-small text-default-400">
                    {selectedKeys === "all"
                        ? "All items selected"
                        : `${selectedKeys.size} of ${filteredItems.length} selected`}
                </span>
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="secondary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
            </div>
        );
    }, [selectedKeys, filteredItems.length, page, pages]);

    return (
        <>
            <Table
                aria-label="Example table with custom cells, pagination and sorting"
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                classNames={{
                    wrapper: "max-h-[382px]",
                }}
                sortDescriptor={sortDescriptor}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
            >
                <TableHeader columns={headerColumns}>
                    {(column) => (
                        <TableColumn
                            key={column.uid}
                            align={column.uid === "actions" ? "center" : "start"}
                            allowsSorting={column.sortable}
                        >
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody emptyContent={"No users found"} items={sortedItems}>
                    {(item) => (
                        <TableRow key={item.id}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <Modal
                isOpen={isLevelModalOpen}
                placement="center"
                onOpenChange={onLevelModalOpenChange}
                style={{ maxWidth: "900px", width: "100%" }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Organization Details</ModalHeader>
                            <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
                                <div className="flex gap-2 items-center">
                                    <Input
                                        variant="bordered"
                                        label="Reg No"
                                        labelPlacement="outside"
                                        defaultValue='11080'
                                    />
                                    <Select
                                        label="Fiscal Year"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="John Doe" value="John Doe">
                                            January-December
                                        </SelectItem>
                                        <SelectItem key="Emily Davis" value="Emily Davis">
                                            April-March
                                        </SelectItem>
                                    </Select>
                                    <Input
                                        variant="bordered"
                                        label="Location"
                                        labelPlacement="outside"
                                        defaultValue='India'
                                    />
                                </div>

                                <div className="flex gap-2 items-center">
                                    <Input
                                        variant="bordered"
                                        label="Address"
                                        labelPlacement="outside"
                                        defaultValue='Chennai'
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Email"
                                        labelPlacement="outside"
                                        defaultValue='kkb@gmail.com'
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Mobile No"
                                        labelPlacement="outside"
                                        defaultValue='877468398'
                                    />
                                </div>

                                <div className="file-input">
                                    <input
                                        type="file"
                                        id="attachment"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="attachment" className="file-label">
                                        <Input
                                            variant="bordered"
                                            label="Organization Logo"
                                            labelPlacement="outside"
                                            readOnly
                                            value={selectedFile ? selectedFile.name : ""}
                                            onClick={() => document.getElementById('attachment').click()}
                                        />
                                    </label>
                                </div>

                            </ModalBody>

                            <ModalFooter className="flex justify-center items-center gap-4">
                                <Button color="danger" variant="flat" onPress={onClose}>
                                    Cancel
                                </Button>
                                <Button color="primary" onPress={handleUpdate}>
                                    Update
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
