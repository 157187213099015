import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    TimeInput,
    Input,
    DatePicker,
    Textarea,
    Select,
    SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";

const VehiclemaintainanceForm = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate("/manager/main");
    };

    return (
        <div className="main-inner-wrapper leavemenu-wrapper">
            <h1 className="report-heading">Vehicle Maintenance Details</h1>
            <div className="px-4">
                <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div className="form-row">

                                    <Input
                                        variant="bordered"
                                        label="Vehicle Registration number"
                                        labelPlacement="outside"
                                    />

                                    <Select
                                        label="Maintenance Type"
                                        className="custom-select"
                                        labelPlacement="outside"
                                        variant="bordered"
                                        fullWidth
                                    >
                                        <SelectItem key="own vehicle" value="own vehicle">
                                            Reactive Maintenance
                                        </SelectItem>

                                        <SelectItem key="Rental vehicle" value="Rental vehicle">
                                            Periodic Maintenance
                                        </SelectItem>

                                    </Select>

                                    <DatePicker
                                        variant="bordered"
                                        label="Date of Service"
                                        labelPlacement="outside"
                                    />



                                </div>
                                <div className="form-row">
                                    <Input
                                        variant="bordered"
                                        label="Mileage at Service"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Service Description"
                                        labelPlacement="outside"
                                    />

                                    <Input
                                        variant="bordered"
                                        label="Service Provider"
                                        labelPlacement="outside"
                                    />
                                </div>
                                {/* </div> */}
                                <div className="form-row">

                                    <Input
                                        variant="bordered"
                                        label="Maintenance Cost $"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <Input
                                        variant="bordered"
                                        label="Parts replaced"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                    <DatePicker
                                        variant="bordered"
                                        label="Next Service Date"
                                        labelPlacement="outside"
                                        type="tel"
                                    />
                                </div>
                                <div className="file-input">
                                    <input
                                        type="file"
                                        id="attachment"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="attachment" className="file-label">
                                        <Input
                                            variant="bordered"
                                            label="Bill/Receipt"
                                            labelPlacement="outside"
                                            readOnly
                                            value={selectedFile ? selectedFile.name : ""}
                                            onClick={() => document.getElementById('attachment').click()}
                                        />
                                    </label>
                                </div>


                                <div className="flex justify-center items-center gap-4 mt-4" >
                                    <Button className="incidentSubmitBtn" type="submit" color="primary">
                                        Submit
                                    </Button>
                                    <Button color="danger" variant="flat" onClick={() => navigate('/manager/main')}>
                                        Cancel
                                    </Button>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default VehiclemaintainanceForm;
