import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TimeInput,
  Input,
  DatePicker,
  Textarea,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "./ModelForm.css";

const FuelForm = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/manager/fuel");
  };

  return (
    <div className="main-inner-wrapper leavemenu-wrapper">
      <h1 className="report-heading">Fuel Details</h1>
      <div className="px-4">
        <div className="leave-apply__wrapper py-8 px-20 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-gray-500 rounded-xl">
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div>
            
              <div className="form-row">
                  <Input
                    variant="bordered"
                    label="Vehicle Reg number"
                    labelPlacement="outside"
                    type="number"
                  />
                  <Select
                    label="Fuel type"
                    className="custom-select"
                    labelPlacement="outside"
                    variant="bordered"
                    fullWidth
                  >
                    <SelectItem key="Petrol(92-Octane)" value="Petrol(92-Octane)">
                      Petrol(92-Octane)
                    </SelectItem>

                    <SelectItem key="Petrol(92-Octane)" value="Petrol(92-Octane)">
                      Petrol(95-Octane)
                    </SelectItem>

                    <SelectItem key="Petrol(92-Octane)" value="Petrol(92-Octane)">
                      Petrol(98-Octane)
                    </SelectItem>

                    <SelectItem key="Petrol(92-Octane)" value="Petrol(92-Octane)">
                      Diesel
                    </SelectItem>

                    <SelectItem key="Petrol(92-Octane)" value="Petrol(92-Octane)">
                      LPG gas
                    </SelectItem>

                  </Select>
                  <Input
                    variant="bordered"
                    label="Fuel Quantity"
                    labelPlacement="outside"
                    type="number"
                  />
                </div>

                <div className="form-row">

                  <Input
                    variant="bordered"
                    label="Odometer Reading"
                    labelPlacement="outside"
                    type="number"
                  />

                  <Input
                    variant="bordered"
                    label="Fuel Expenses"
                    labelPlacement="outside"
                    type="number"
                  />

                </div>


                <div className="flex justify-center items-center gap-4 mt-4" >
                  <Button className="incidentSubmitBtn" type="submit" color="primary">
                    Submit
                  </Button>
                  <Button color="danger" variant="flat" onClick={() => navigate('/manager/fuel')}>
                    Cancel
                  </Button>
                </div>

              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default FuelForm;
